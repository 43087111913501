import PrecheckInputs from "../../../constants/PrecheckInputs"
import { getResourceBundle } from "../../../tools/AppUtil"

const rb = getResourceBundle()

export const CFG_DATABASE_OPTIONS = {
  key: "mongodb_database",
  label: "MongoDB (Enterprise Edition)"
}

export const CFG_FIELD_NAMES = {
  name: "name",
  description: "description",
  masterKeyId: "masterKeyId",
  clientCertInfo: "clientCertInfo",
  clientCert: "clientCert",
  password: "password",
  caCert: "caCert"
}

export const CFGInitialFormData = {
  [CFG_FIELD_NAMES.name]: "",
  [CFG_FIELD_NAMES.description]: "",
  [CFG_FIELD_NAMES.masterKeyId]: "",
  [CFG_FIELD_NAMES.clientCert]: "",
  [CFG_FIELD_NAMES.password]: "",
  [CFG_FIELD_NAMES.caCert]: ""
}

export const CFGIntitalFileNames = {
  [CFG_FIELD_NAMES.clientCert]: "",
  [CFG_FIELD_NAMES.caCert]: ""
}

const nameConfig = {
  label: rb.NAME,
  name: CFG_FIELD_NAMES.name,
  type: "text",
  placeholder: rb.KMS_CONFIG_NAME_PLACEHOLDER,
  validation: {
    regex: PrecheckInputs.encryption_kms_cte.name,
    errorMessage: rb.KMS_NAME_ERROR_MESSAGE
  }
}

const descriptionConfig = {
  label: rb.DESCRIPTION,
  name: CFG_FIELD_NAMES.description,
  type: "text",
  placeholder: rb.KMS_FORM_DESCRIPTION_PLACEHOLDER,
  optional: true
}

const keyIdentifierConfig = {
  name: CFG_FIELD_NAMES.masterKeyId,
  label: rb.KMS_CFG_KEY_IDENTIFIER,
  type: "text",
  placeholder: rb.KMS_CFG_KEY_IDENTIFIER_PLACEHOLDER,
  validation: {
    regex: PrecheckInputs.encryption_kms_cte.key_identifier_text,
    errorMessage: rb.KMS_CFG_KEY_ID_ERROR
  }
}

const clientCertificateConfig = {
  name: CFG_FIELD_NAMES.clientCert,
  label: rb.KMS_CFG_CLIENT_CERTIFICATE,
  type: "fileText",
  placeholder: rb.KMS_CFG_CERTIFICATE_PLACEHOLDER,
  customLabel: true,
  helperText: rb.KMS_CFG_CLIENT_CERT_TOOLTIP,
  validation: {
    regex: PrecheckInputs.encryption_kms_cte.certificate_text,
    errorMessage: rb.CTE_NAME_ERROR_MESSAGE
  }
}

const clientPassword = {
  name: CFG_FIELD_NAMES.password,
  label: rb.CLIENT_CERTIFICATE_PASS,
  type: "password",
  placeholder: rb.CLIENT_CERTIFICATE_PASS_PLACEHOLDER,
  optional: true,
  customLabel: true,
  helperText: rb.KMS_CFG_CLIENT_PASSWORD_TOOLTIP
}

const serverCACertificateConfig = {
  name: CFG_FIELD_NAMES.caCert,
  label: rb.KMS_CFG_SERVER_CA_CERTIFICATE,
  type: "fileText",
  placeholder: rb.KMS_CFG_CERTIFICATE_PLACEHOLDER,
  customLabel: true,
  helperText: rb.KMS_CFG_SERVER_CA_CERT_TOOLTIP,
  validation: {
    regex: PrecheckInputs.encryption_kms_cte.certificate_text,
    errorMessage: rb.CTE_NAME_ERROR_MESSAGE
  }
}

export const MongoCFGConfig = [
  nameConfig,
  descriptionConfig,
  keyIdentifierConfig,
  clientCertificateConfig,
  clientPassword,
  serverCACertificateConfig
]
