import {
  CTE_ENCRYPTION_SPEC,
  KMS_ENCRYPTION_SPEC,
  ENCRYPTIONS_SPEC
} from "../data/DataURLConstants"
import FetchUtil from "../tools/FetchUtil"

export const getAllEncryptionConfigs = (type) => {
  const url = ENCRYPTIONS_SPEC.url + `/${type}`
  return fetch(url, { headers: FetchUtil.headers() })
}

export const getAllKMS = (limit = 20, offset = 0) => {
  return fetch(
    KMS_ENCRYPTION_SPEC.url + "/?limit=" + limit + "&offset=" + offset,
    { headers: FetchUtil.headers() }
  )
}

export const getAllCTE = (limit = 20, offset = 0) => {
  return fetch(
    CTE_ENCRYPTION_SPEC.url + "/?limit=" + limit + "&offset=" + offset,
    { headers: FetchUtil.headers() }
  )
}

export const getKMSbyID = (kmsId) => {
  return fetch(KMS_ENCRYPTION_SPEC.url + "/" + kmsId, {
    headers: FetchUtil.headers()
  })
}

export const getCTEbyID = (cteId) => {
  return fetch(CTE_ENCRYPTION_SPEC.url + "/" + cteId, {
    headers: FetchUtil.headers()
  })
}

export const getConfigforKMS = (
  kmsId,
  dbType = "mongodb_database",
  limit = 20,
  offset = 0
) => {
  return fetch(
    KMS_ENCRYPTION_SPEC.url +
      "/" +
      kmsId +
      "/cfg/?load-config-details=True&db_type=" +
      dbType +
      "&limit=" +
      limit +
      "&offset=" +
      offset,
    { headers: FetchUtil.headers() }
  )
}

export const postKMS = (body) => {
  return FetchUtil.POST(KMS_ENCRYPTION_SPEC.url, body)
}

export const postCTE = (body) => {
  return FetchUtil.POST(CTE_ENCRYPTION_SPEC.url, body)
}

export const updateKMS = (kmsId, body) => {
  return FetchUtil.PUT(KMS_ENCRYPTION_SPEC.url + "/" + kmsId, body)
}

export const updateCTE = (cteId, body) => {
  return FetchUtil.PUT(CTE_ENCRYPTION_SPEC.url + "/" + cteId, body)
}

export const deleteKMS = (kmsId) => {
  return FetchUtil.DELETE(KMS_ENCRYPTION_SPEC.url + "/" + kmsId)
}

export const deleteCTE = (cteId) => {
  return FetchUtil.DELETE(CTE_ENCRYPTION_SPEC.url + "/" + cteId)
}

export const postKMSConfig = (kmsId, body) => {
  return FetchUtil.POST(KMS_ENCRYPTION_SPEC.url + "/" + kmsId + "/cfg/", body)
}
export const deleteKMSConfig = (kmsId, configId) => {
  return FetchUtil.DELETE(
    KMS_ENCRYPTION_SPEC.url + "/" + kmsId + "/cfg/" + configId
  )
}
