export const encryptionInitialState = {
  showKMSForm: false,
  showRemoveModal: false,
  kmsFormError: "", // To track errors in the KMS form
  removeFormError: "", // To track errors in the remove modal
  selectedRowKeys: [], // to manage selected item
  showSuccessfulAlert: false,
  isKMSFormLoading: false,
  isRemoveModalLoading: false
}
export const encryptionActionTypes = {
  OPEN_KMS_FORM: "OPEN_KMS_FORM",
  CLOSE_KMS_FORM: "CLOSE_KMS_FORM",
  OPEN_REMOVE_MODAL: "OPEN_REMOVE_MODAL",
  CLOSE_REMOVE_MODAL: "CLOSE_REMOVE_MODAL",
  SET_KMS_FORM_ERROR: "SET_KMS_FORM_ERROR",
  SET_REMOVE_FORM_ERROR: "SET_REMOVE_FORM_ERROR",
  SET_SELECTED_ROW_KEYS: "SET_SELECTED_ROW_KEYS",
  SHOW_SUCCESS_ALERT: "SHOW_SUCCESS_ALERT",
  HIDE_SUCCESS_ALERT: "HIDE_SUCCESS_ALERT",
  SET_KMS_FORM_LOADING: "SET_KMS_FORM_LOADING",
  SET_REMOVE_FORM_LOADING: "SET_REMOVE_FORM_LOADING"
}

export const encryptionReducer = (state, action) => {
  switch (action.type) {
    case encryptionActionTypes.OPEN_KMS_FORM:
      return {
        ...state,
        showKMSForm: true,
        kmsFormError: "",
        showSuccessfulAlert: false,
        isKMSFormLoading: false
      }
    case encryptionActionTypes.CLOSE_KMS_FORM:
      return {
        ...state,
        showKMSForm: false,
        kmsFormError: "",
        isKMSFormLoading: false
      }
    case encryptionActionTypes.OPEN_REMOVE_MODAL:
      return {
        ...state,
        showRemoveModal: true,
        removeFormError: "",
        isRemoveModalLoading: false
      }
    case encryptionActionTypes.CLOSE_REMOVE_MODAL:
      return {
        ...state,
        showRemoveModal: false,
        removeFormError: "",
        isRemoveModalLoading: false
      }
    case encryptionActionTypes.SET_KMS_FORM_ERROR:
      return { ...state, kmsFormError: action.payload, isKMSFormLoading: false }
    case encryptionActionTypes.SET_REMOVE_FORM_ERROR:
      return {
        ...state,
        removeFormError: action.payload,
        isRemoveModalLoading: false
      }
    case encryptionActionTypes.SET_SELECTED_ROW_KEYS:
      return { ...state, selectedRowKeys: action.payload }
    case encryptionActionTypes.SHOW_SUCCESS_ALERT:
      return { ...state, showSuccessfulAlert: true }
    case encryptionActionTypes.HIDE_SUCCESS_ALERT:
      return { ...state, showSuccessfulAlert: false }
    case encryptionActionTypes.SET_KMS_FORM_LOADING:
      return { ...state, isKMSFormLoading: true }
    case encryptionActionTypes.SET_REMOVE_FORM_LOADING:
      return { ...state, isRemoveModalLoading: true }
    default:
      return state
  }
}
