import { ALERTS, ALERT_POLICIES } from "../data/DataURLConstants"
import FetchUtil from "../tools/FetchUtil"

const getAlerts = (body) => {
  let url = `${ALERTS.url}`
  let queryString = new URLSearchParams()
  for (let key in body) {
    if (body.hasOwnProperty(key)) {
      queryString.append(
        key,
        typeof body[key] === "object" ? JSON.stringify(body[key]) : body[key]
      )
    }
  }
  if (!!queryString.toString()) {
    url = `${url}?${queryString.toString()}`
  }
  return fetch(url, {
    headers: FetchUtil.headers()
  })
}

const getAlertsCount = (body) => {
  let url = `${ALERTS.url}/alertCounts`
  let queryString = new URLSearchParams()
  for (let key in body) {
    if (body.hasOwnProperty(key)) {
      queryString.append(
        key,
        typeof body[key] === "object" ? JSON.stringify(body[key]) : body[key]
      )
    }
  }
  if (!!queryString.toString()) {
    url = `${url}?${queryString.toString()}`
  }
  return fetch(url, {
    headers: FetchUtil.headers()
  })
}

const updateAlerts = (body) => {
  return FetchUtil.PUT(`${ALERTS.url}`, body)
}

const getAlertPolicies = () => {
  return fetch(`${ALERT_POLICIES.url}`, {
    headers: FetchUtil.headers()
  })
}

const updateAlertPolicies = (body) => {
  return FetchUtil.PUT(`${ALERT_POLICIES.url}/${body.id}`, body)
}

const getPurgeInterval = () => {
  return fetch(`${ALERTS.url}/purgeInterval`, {
    headers: FetchUtil.headers()
  })
}

const updatePurgeInterval = (body) => {
  return FetchUtil.PUT(`${ALERTS.url}/purgeInterval`, body)
}

const resolveAlertsByEntity = (body) => {
  let url = ALERTS.url
  return FetchUtil.PATCH(url, body)
}

export {
  getAlerts,
  getAlertsCount,
  updateAlerts,
  getAlertPolicies,
  updateAlertPolicies,
  getPurgeInterval,
  updatePurgeInterval,
  resolveAlertsByEntity
}
