import React from "react"
import PropTypes from "prop-types"
import {
  ErrorSquareIcon,
  SuccessSquareIcon,
  InformationSquareIcon,
  StorageIcon,
  TimeIcon,
  PauseIcon,
  RefreshIcon,
  SyncIcon,
  CheckMarkIcon,
  TriangleLeftIcon,
  TriangleUpIcon,
  ShowIcon,
  AlertTriangleIcon
} from "@nutanix-ui/prism-reactjs"

const IconElementMap = {
  "info-circle": InformationSquareIcon,
  "check-circle": SuccessSquareIcon,
  "check-circle-o": CheckMarkIcon,
  "close-circle": ErrorSquareIcon,
  "clock-circle-o": TimeIcon,
  "pause-circle": PauseIcon,
  reload: RefreshIcon,
  "caret-left": TriangleLeftIcon,
  "caret-up": TriangleUpIcon,
  eye: ShowIcon,
  "warning-triangle": AlertTriangleIcon,
  "sync-o": SyncIcon,
  storage: StorageIcon
}

class EraIcons extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const Icon = IconElementMap[this.props.type]
    return <Icon {...this.props} />
  }
}

EraIcons.propTypes = {
  type: PropTypes.string
}

export default EraIcons
