import {
  InformationSquareIcon,
  Link,
  Sorter,
  Table,
  Tooltip
} from "@nutanix-ui/prism-reactjs"
import { getUsername } from "../../tools/AuthUtil"
import {
  dateSorter,
  formatDate,
  getDbType,
  getResourceBundle,
  numberSorter,
  textSorter
} from "../../tools/AppUtil"

const rb = getResourceBundle()

const toolTipContent = rb.KMS_TOOL_TIP

const KMSUtils = {
  textSorter,
  numberSorter,
  dateSorter,
  formatDate
}

const AuthUtils = {
  getUsername
}

export const KMSCTEDropDownKeys = {
  update: "updateKMSCTE",
  remove: "removeKMSCTE"
}

export const KMSCTEConfigValues = {
  pageSizeOptions: [5, 10, 15, 20, 25, 30, 35, 40]
}

export const CTEListColumns = [
  {
    title: rb.NAME,
    dataIndex: "name"
  },
  {
    title: rb.IN_USE,
    dataIndex: "inUse",
    textAlign: "right"
  },
  {
    title: rb.OWNER,
    dataIndex: "ownerId",
    render: (text) => AuthUtils.getUsername(text)
  },
  {
    title: rb.DATE_CREATED,
    dataIndex: "dateCreated",
    render: (text) => KMSUtils.formatDate(text)
  }
]

export const KMSConfigListColumns = [
  {
    title: rb.NAME,
    dataIndex: "name",
    sorter: (a, b) => KMSUtils.textSorter(a.name, b.name),
    render: (text) => {
      return <div className="table-row-nowrap">{text}</div>
    }
  },
  {
    title: rb.DATABASE,
    dataIndex: "databaseType",
    sorter: (a, b) => KMSUtils.numberSorter(a.database, b.database),
    render: (text) => {
      return <div className="table-row-nowrap">{getDbType(text)}</div>
    }
  },
  {
    title: rb.IN_USE,
    dataIndex: "databaseCount",
    sorter: (a, b) => KMSUtils.numberSorter(a.databaseCount, b.databaseCount),
    render: (databaseCount) => {
      return <div className="table-row-nowrap">{databaseCount}</div>
    },
    align: "right"
  },
  {
    title: rb.OWNER,
    dataIndex: "ownerId",
    render: (text) => AuthUtils.getUsername(text)
  },
  {
    title: rb.DATE_CREATED,
    dataIndex: "dateCreated",
    sorter: (a, b) => KMSUtils.dateSorter(a.dateCreated, b.dateCreated),
    render: (text) => KMSUtils.formatDate(text)
  }
]

export const KMSListColumns = (handleNavigate) => [
  {
    title: rb.NAME,
    dataIndex: "name",
    render: (text, record) => (
      <Link
        type="table-nav-primary"
        onClick={() => handleNavigate(record.id, record.name)}
        style={{
          cursor: "pointer"
        }}>
        {text}
      </Link>
    )
  },
  {
    title: rb.CONFIGURATION,
    dataIndex: "configCount",
    textAlign: "right",
    render: (text) => (
      <div>
        {text}
        {text === 0 && (
          <Tooltip
            oldTooltip={false}
            content={toolTipContent}
            contentProps={{
              style: {
                maxWidth: 300
              },
              "data-test-id": "basic-tooltip"
            }}>
            <InformationSquareIcon style={{ marginLeft: 5 }} />
          </Tooltip>
        )}
      </div>
    )
  },
  {
    title: rb.OWNER,
    dataIndex: "ownerId",
    render: (text) => AuthUtils.getUsername(text)
  },
  {
    title: rb.DATE_CREATED,
    dataIndex: "dateCreated",
    render: (text) => KMSUtils.formatDate(text)
  }
]

export const KMSDefaultSortInfo = {
  order: Sorter.SORT_ORDER_CONST.DESCEND,
  column: "dateCreated",
  sortable: ["name", "dateCreated", "ownerId", "configCount"],
  columnsSorterProps: {
    name: {
      "data-test-id": "name-sorter-button"
    },
    dateCreated: {
      "data-test-id": "dateCreated-sorter-button"
    },
    ownerId: {
      "data-test-id": "ownerId-sorter-button"
    },
    configCount: {
      "data-test-id": "configCount-sorter-button"
    }
  }
}

export const CTEDefaultSortInfo = {
  order: Sorter.SORT_ORDER_CONST.DESCEND,
  column: "dateCreated",
  sortable: ["name", "dateCreated", "ownerId", "inUse"],
  columnsSorterProps: {
    name: {
      "data-test-id": "name-sorter-button"
    },
    dateCreated: {
      "data-test-id": "dateCreated-sorter-button"
    },
    ownerId: {
      "data-test-id": "ownerId-sorter-button"
    },
    inUse: {
      "data-test-id": "inUse-sorter-button"
    }
  }
}

export const EncryptionRadioProps = {
  type: Table.ROW_SELECTION_TYPES.RADIO,
  radioProps: (rowData) => {
    const props = {
      "aria-label": `Select ${rowData.name}`,
      "data-test-id": "select-row-id"
    }
    return props
  }
}

export const KMSColumnStructure = {
  paginationPosition: {
    top: true,
    bottom: true
  },
  columnWidths: {
    configCount: "20%",
    ownerId: "20%",
    dateCreated: "20%",
    name: "40%"
  },
  columnResize: true,
  showLinks: true
}

export const CTEColumnStructure = {
  paginationPosition: {
    top: true,
    bottom: true
  },
  columnWidths: {
    inUse: "20%",
    ownerId: "20%",
    dateCreated: "20%",
    name: "40%"
  },
  columnResize: true
}
