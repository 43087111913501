import { Navigate, useLocation, useNavigate } from "react-router-dom"

export default function ProtectedRoute({ children }) {
  const isAuthorised = localStorage.getItem("authorized")
  const location = useLocation()
  const navigate = useNavigate()
  if (isAuthorised !== "true") {
    // user is not authenticated
    return <Navigate to="/login" />
  } else if (location.pathname === "/") {
    navigate("/dashboard")
  }
  return children
}
