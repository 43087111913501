//import "./App.css"
/* Prism UI Component Imports. */
import "@nutanix-ui/prism-reactjs/dist/index.css"
import {
  FlexLayout,
  MainPageLayout,
  Menu,
  MenuGroup,
  MenuItem,
  StackingLayout,
  Title,
  Divider,
  LockIcon,
  SettingsIcon,
  UnlockIcon,
  Truncate
} from "@nutanix-ui/prism-reactjs"
import React from "react"
import { Outlet } from "react-router-dom"
import PATH_CONSTANTS from "./routes/pathConstants"
import HeaderNav from "./components/HeaderNav"
import withRouter from "./hooks/withRouter"
import { getResourceBundle } from "./tools/AppUtil"
import { configFlags } from "./tools/FlagUtil"
import { hasPrivilege } from "./tools/AuthUtil"
import privileges from "./constants/privileges"

const AppUtil = {
  getResourceBundle
}
const AuthUtil = { hasPrivilege }
const rb = AppUtil.getResourceBundle()

const TruncateSpan = Truncate("span")

class App extends React.Component {
  constructor(props) {
    super(props)
    const { pathname } = props?.location
    const { showSettingsMenu, activePath, openPaths } =
      this.handlePathSegments(pathname)
    this.state = {
      leftPanelVisible: true,
      lockLeftPanel: false,
      showSettingsMenu,
      activePath,
      openPaths
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.onRouteChanged()
      const { pathname } = this.props?.location
      const { showSettingsMenu, activePath } = this.handlePathSegments(pathname)
      this.setState({
        showSettingsMenu,
        activePath
      })
    }
  }

  handlePathSegments(pathname) {
    const paths = pathname.split("/")
    paths.shift() // remove empty string at array index 0
    const module = paths[0]
    const showSettingsMenu = module === "settings"
    const activePath = paths?.map((path) => "/" + path) || []
    const openPaths = [activePath]
    return { showSettingsMenu, activePath, openPaths }
  }

  onRouteChanged() {
    let title = this.props.location.pathname
    title = title.substring(1).toUpperCase()
    title = title === "" ? "LOGIN" : title
  }

  sameMenuItemClicked(e, showSettingsMenu) {
    if (e.key.includes("_internal")) {
      this.setState({
        openPaths: e.openKeyMap
      })
    } else {
      this.setState({
        showSettingsMenu,
        leftPanelVisible: this.state.lockLeftPanel,
        activePath: e.keyPath,
        openPaths: e.openKeyMap
      })
    }
  }

  menuOnClick(e) {
    const { pathname } = this.props.location
    let showSettingsMenu = false
    let navigateTo = ""
    e?.keyPath?.forEach((path) => {
      navigateTo = navigateTo + path
    })

    if (
      e.keyPath.length === 1 &&
      e.keyPath[0] === `/${PATH_CONSTANTS.SETTINGS}`
    ) {
      showSettingsMenu = this.state.showSettingsMenu ? false : true
    } else {
      showSettingsMenu = this.state.showSettingsMenu
    }
    if (navigateTo === `/${PATH_CONSTANTS.SETTINGS}`) {
      this.settingsMenuOnClick(e, navigateTo, pathname, showSettingsMenu)
    } else {
      this.leftMenuOnClick(e, navigateTo, pathname, showSettingsMenu)
    }
  }

  leftMenuOnClick(e, navigateTo, pathname, showSettingsMenu) {
    const { navigate } = this.props
    if (navigateTo === pathname || pathname.includes(navigateTo)) {
      this.sameMenuItemClicked(e, showSettingsMenu)
    } else {
      this.setState(
        {
          showSettingsMenu,
          leftPanelVisible: this.state.lockLeftPanel,
          activePath: e.keyPath,
          openPaths: e.openKeyMap
        },
        () => {
          navigate(navigateTo)
        }
      )
    }
  }

  settingsMenuOnClick(e, navigateTo, pathname, showSettingsMenu) {
    const { navigate } = this.props
    if (!e.key.includes("_internal")) {
      if (navigateTo === pathname) {
        this.sameMenuItemClicked(e, showSettingsMenu)
      } else {
        const defaultSettingNavPath = "/settings/ndbservices" //default to /ndbservices on clicking settings
        navigateTo = pathname.includes(`/${PATH_CONSTANTS.SETTINGS}`)
          ? pathname
          : defaultSettingNavPath
        const { activePath } = this.handlePathSegments(navigateTo)
        this.setState(
          {
            showSettingsMenu,
            leftPanelVisible: this.state.lockLeftPanel,
            activePath: activePath,
            openPaths: e.openKeyMap
          },
          () => {
            if (showSettingsMenu) navigate(navigateTo)
          }
        )
      }
    } else {
      this.setState({
        leftPanelVisible: this.state.lockLeftPanel,
        openPaths: e.openKeyMap
      })
    }
  }

  toggleLeftPanel() {
    this.setState({
      leftPanelVisible: !this.state.leftPanelVisible
    })
  }

  setLockLeftPanel() {
    this.setState({
      lockLeftPanel: !this.state.lockLeftPanel
    })
  }

  render() {
    const settingsAndContent = (
      <FlexLayout
        className="settingsAndContent"
        flexDirection="row"
        height="100%">
        {this.state.showSettingsMenu ? (
          <Menu
            padding="10px-0px"
            type={Menu.MenuType.PRIMARY}
            theme="light"
            role={Menu.MenuRole.MENUBAR}
            className="settingsMenu"
            data-test-id="settings-menu"
            onClick={(e) => this.menuOnClick(e)}
            activeKeyPath={this.state.activePath}
            openKeyMap={this.state.openPaths}
            height="100%">
            <StackingLayout padding="0px-20px">
              <Title className="settingsMenuTitle" size="h1">
                {rb.SETTINGS}
              </Title>
              <Divider type="short" />
            </StackingLayout>
            <MenuGroup key="/settings" title="">
              <MenuItem index key="/ndbservices" data-test-id="ndb-services">
                {rb.ERA_SERVICE}
              </MenuItem>
              <MenuItem key="/nutanixClusters" data-test-id="nutanix-clusters">
                {rb.NUTANIX_CLUSTERS}
              </MenuItem>
              {configFlags.OBJECT_STORES_ENABLED && (
                <MenuItem key="/prismcentral" data-test-id="prism-central">
                  {rb.PRISM_CENTRAL}
                </MenuItem>
              )}
              {configFlags.OBJECT_STORES_ENABLED && (
                <MenuItem key="/objectstore" data-test-id="object-store">
                  {rb.OBJECT_STORES}
                </MenuItem>
              )}
              <MenuItem key="/networks" data-test-id="networks">
                {rb.NETWORKS}
              </MenuItem>
              <MenuGroup
                key="/access"
                type="collapsible"
                title={rb.ACCESS_CONTROL}
                data-test-id="access">
                {AuthUtil.hasPrivilege(privileges.ADD_USER) && (
                  <MenuItem key="/users" data-test-id="users">
                    {rb.USERS}
                  </MenuItem>
                )}
                {AuthUtil.hasPrivilege(privileges.ADD_GROUP) && (
                  <MenuItem key="/groups" data-test-id="groups">
                    {rb.GROUPS}
                  </MenuItem>
                )}
                {AuthUtil.hasPrivilege(privileges.CREATE_ROLE) && (
                  <MenuItem key="/roles" data-test-id="roles">
                    {rb.ROLES}
                  </MenuItem>
                )}
                {AuthUtil.hasPrivilege(privileges.CONFIGURE_AUTH_DIR) && (
                  <MenuItem key="/authentication" data-test-id="authentication">
                    {rb.AD_CONFIG}
                  </MenuItem>
                )}
                <MenuItem
                  key="/entitysharingpolicy"
                  data-test-id="entity-sharing-policy">
                  {rb.ENTITY_SHARING}
                </MenuItem>
              </MenuGroup>
              {(!configFlags.DISABLE_TDE_WITH_KMIP ||
                !configFlags.DISABLE_CTE_INTEROPERABILITY) && (
                <MenuGroup
                  key="/encryption"
                  type="collapsible"
                  title={rb.ENCRYPTION}>
                  {!configFlags.DISABLE_TDE_WITH_KMIP && (
                    <MenuItem key="/kms" data-test-id="encryption-kms">
                      {rb.EXTERNAL_KMS}
                    </MenuItem>
                  )}
                  {!configFlags.DISABLE_CTE_INTEROPERABILITY && (
                    <MenuItem key="/cte" data-test-id="encryption-cte">
                      <TruncateSpan>{rb.CTE}</TruncateSpan>
                    </MenuItem>
                  )}
                </MenuGroup>
              )}
              {AuthUtil.hasPrivilege(privileges.CREATE_TAG) && (
                <MenuItem key="/tags" data-test-id="tags">
                  {rb.TAGS}
                </MenuItem>
              )}
              {AuthUtil.hasPrivilege(privileges.GENERATE_DIAG_BUNDLE) && (
                <MenuItem key="/diagnostic" data-test-id="diagnostics">
                  {rb.DIAGNOSTICS}
                </MenuItem>
              )}
              {AuthUtil.hasPrivilege(privileges.UPGRADE_ERA) && (
                <MenuItem key="/maintenance" data-test-id="maintenance">
                  {rb.MAINTENANCE}
                </MenuItem>
              )}
              <MenuItem key="/migration" data-test-id="migration">
                {rb.MIGRATION}
              </MenuItem>
              <Divider type="short" />
              <MenuItem key="/startEra" data-test-id="start-ndb">
                {rb.GETTING_STARTED}
              </MenuItem>
            </MenuGroup>
          </Menu>
        ) : null}
        <Outlet context={[this.state.leftPanelVisible]} />
      </FlexLayout>
    )

    // Left Nav Menu
    const leftPanel = (
      <FlexLayout
        className="left-nav-menu"
        flexDirection="column"
        padding="15px"
        itemSpacing="10px">
        <Menu
          type={Menu.MenuType.SECONDARY}
          padding="10px-0px"
          theme="dark"
          data-test-id="left-nav-menu"
          onClick={(e) => this.menuOnClick(e)}
          activeKeyPath={this.state.activePath}
          openKeyMap={this.state.openPaths}
          role={Menu.MenuRole.MENUBAR}>
          <MenuItem key="/dashboard" data-test-id="dashboard">
            {rb.DASHBOARD}
          </MenuItem>
          <Divider type="short" />
          <MenuItem key="/databases" data-test-id="databases">
            {rb.DATABASES}
          </MenuItem>
          <MenuGroup key="/dp" title={rb.DATA_PROTECTION} type="collapsible">
            <MenuItem key="/timemachines" data-test-id="timemachines">
              {rb.TIME_MACHINES}
            </MenuItem>
            {configFlags.DISASTER_RECOVERY_ENABLED && (
              <MenuItem
                key="/disasterrecovery"
                data-test-id="disaster-recovery">
                {rb.DISASTER_RECOVERY}
              </MenuItem>
            )}
          </MenuGroup>
          <MenuGroup key="/profiles" title={rb.PROFILES} type="collapsible">
            <MenuItem key="/software" data-test-id="software-profiles">
              {rb.SOFTWARE}
            </MenuItem>
            <MenuItem key="/compute" data-test-id="compute-profiles">
              {rb.COMPUTE}
            </MenuItem>
            <MenuItem key="/network" data-test-id="network-profiles">
              {rb.NETWORK}
            </MenuItem>
            <MenuItem key="/dbparameters" data-test-id="db-params-profiles">
              {rb.DB_PARAMS}
            </MenuItem>
            <MenuItem
              key="/windowsdomain"
              data-test-id="windows-domain-profiles">
              {rb.WIN_DOMAIN}
            </MenuItem>
          </MenuGroup>
          <MenuItem key="/dbservervms" data-test-id="db-server-vms">
            {rb.DB_SERVER_VMS}
          </MenuItem>
          <MenuGroup key="/policies" title={rb.POLICIES} type="collapsible">
            <MenuItem key="/sla" data-test-id="slas">
              {rb.SLAS}
            </MenuItem>
            <MenuItem
              key="/maintenanceWindows"
              data-test-id="maintenance-windows">
              {rb.MAINTENANCE_WINDOW}
            </MenuItem>
          </MenuGroup>
          <MenuItem key="/operations" data-test-id="operations">
            {rb.OPERATIONS}
          </MenuItem>
          <MenuItem key="/alerts" data-test-id="alerts">
            {rb.ALERTS}
          </MenuItem>
          <Divider type="short" />
          <MenuItem key="/settings" data-test-id="settings-menu">
            <SettingsIcon />
            {rb.SETTINGS}
          </MenuItem>
        </Menu>
        <StackingLayout
          className="lock-nav-bar"
          data-test-id="lock-nav-bar"
          onClick={() => this.setLockLeftPanel()}>
          <Divider />
          <FlexLayout flexDirection="row" itemSpacing="10px">
            {this.state.lockLeftPanel ? <UnlockIcon /> : <LockIcon />}
            <div>
              {this.state.lockLeftPanel ? rb.UNLOCK_NAV_BAR : rb.LOCK_NAV_BAR}
            </div>
          </FlexLayout>
        </StackingLayout>
      </FlexLayout>
    )
    const ndbHeader = (
      <HeaderNav
        selectedMenu={"NDB"}
        menuIconClickHandler={() => this.toggleLeftPanel()}
      />
    )

    return (
      <div className="App">
        <MainPageLayout
          header={ndbHeader}
          leftPanel={leftPanel}
          leftPanelVisible={this.state.leftPanelVisible}
          body={settingsAndContent}
          bodyCompressed={true}
          data-test-id="compressed-body"
        />
      </div>
    )
  }
}

export default withRouter(App)
