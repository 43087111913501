import React, { Suspense } from "react"
import { Routes, Route, Outlet, Navigate } from "react-router-dom"
import ProtectedRoute from "../components/ProtectedRoute"
import App from "../App"
import Login from "../containers/LoginPage/LoginPage"
import Eula from "../containers/Eula"
import NotFound from "../components/NotFound"
import { Loader } from "@nutanix-ui/prism-reactjs"
import { getResourceBundle } from "../tools/AppUtil"
import KMSConfigPage from "../pages/encryption/KMSConfigPage"
import { configFlags } from "../tools/FlagUtil"

const AppUtil = {
  getResourceBundle
}
const rb = AppUtil.getResourceBundle()
const RegisterCluster = React.lazy(() =>
  import("../pages/gettingStarted/RegisterCluster")
)
const EraConfig = React.lazy(() => import("../pages/eraConfig/EraConfig"))
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"))
const Databases = React.lazy(() => import("../pages/databases/Databases"))
const DatabasesContainer = React.lazy(() =>
  import("../pages/databases/DatabasesContainer")
)
const DatabaseList = React.lazy(() => import("../pages/databases/DatabaseList"))
const CloneList = React.lazy(() => import("../pages/databases/CloneList"))
const DatabaseDetails = React.lazy(() =>
  import("../pages/databases/DatabaseDetail")
)
const ClusterDbDetails = React.lazy(() =>
  import("../pages/databases/ClusterDbDetail")
)

/*const DisasterRecovery = React.lazy(() =>
  import("../pages/dataprotection/DisasterRecovery")
)
const DisasterRecoveryOverview = React.lazy(() =>
  import("../pages/dataprotection/disasterrecovery/DisasterRecoveryOverview")
)
const DisasterRecoveryList = React.lazy(() =>
  import("../pages/dataprotection/disasterrecovery/DisasterRecoveryList")
)*/

const TimeMachinesContainer = React.lazy(() =>
  import("../pages/timeMachines/TimeMachinesContainer")
)
const TimeMachineList = React.lazy(() =>
  import("../pages/timeMachines/TimeMachinesList")
)
const TimeMachinesOverview = React.lazy(() =>
  import("../pages/timeMachines/TimeMachines")
)
const TimeMachineDetail = React.lazy(() =>
  import("../pages/timeMachines/TimeMachineDetail")
)
const TimeMachineDetailNew = React.lazy(() =>
  import("../pages/timeMachines/TimeMachineDetailNew")
)
const TimeMachineContainer = React.lazy(() =>
  import("../pages/timeMachines/TimeMachineContainer")
)
const SnapshotManagement = React.lazy(() =>
  import("../pages/timeMachines/SnapshotManagement")
)
const DataAccessManagement = React.lazy(() =>
  import("../pages/timeMachines/DataAccessManagement")
)
const TimeMachineAlerts = React.lazy(() =>
  import("../pages/timeMachines/TimeMachineAlerts")
)
const TimeMachineProperties = React.lazy(() =>
  import("../pages/timeMachines/TimeMachineProperties")
)

const SoftwareProfiles = React.lazy(() =>
  import("../pages/profiles/SoftwareProfiles")
)
const SoftwareProfile = React.lazy(() =>
  import("../pages/profiles/SoftwareProfile")
)
const ComputeProfiles = React.lazy(() =>
  import("../pages/profiles/ComputeProfiles")
)
const NetworkProfiles = React.lazy(() =>
  import("../pages/profiles/NetworkListProfiles")
)
const DBParamsProfiles = React.lazy(() =>
  import("../pages/profiles/DBParameters")
)
const WindowsDomainProfiles = React.lazy(() =>
  import("../pages/profiles/DomainProfiles")
)

const DbServerVMs = React.lazy(() => import("../pages/dbServers/DbServers"))
const DbServerList = React.lazy(() => import("../pages/dbServers/DbServerList"))
const DbServerVMsContainer = React.lazy(() =>
  import("../pages/dbServers/DbServerVMsContainer")
)
const DbServerDetail = React.lazy(() =>
  import("../pages/dbServers/DbServerDetail")
)
const ClusterDbServerDetail = React.lazy(() =>
  import("../pages/dbServers/ClusterDbServerDetail")
)

const SLA = React.lazy(() => import("../pages/SLAs"))
const MaintenanceWindows = React.lazy(() =>
  import("../pages/policies/MaintenanceWindowList")
)
const MaintenanceWindow = React.lazy(() =>
  import("../pages/policies/MaintenanceWindow")
)

const OperationsContainer = React.lazy(() =>
  import("../pages/operations/OperationsContainer")
)
const CurrentOperations = React.lazy(() =>
  import("../pages/operations/Operations")
)
const ScheduledOperations = React.lazy(() =>
  import("../pages/operations/ScheduledOperations")
)

const AlertsContainer = React.lazy(() =>
  import("../pages/alerts/AlertsContainer")
)
const AlertsList = React.lazy(() => import("../pages/alerts/Alerts"))
const AlertsPolicies = React.lazy(() =>
  import("../pages/alerts/Alertspolicies")
)

const NDBServices = React.lazy(() =>
  import("../pages/administration/Administration")
)
const NutanixClusters = React.lazy(() =>
  import("../pages/administration/NutanixClusters")
)
const PrismCentral = React.lazy(() =>
  import("../pages/administration/PrismCentral")
)
const ObjectStore = React.lazy(() =>
  import("../pages/administration/ObjectStores")
)
const Users = React.lazy(() => import("../pages/administration/Users"))
const Groups = React.lazy(() => import("../pages/administration/Groups"))
const Roles = React.lazy(() => import("../pages/administration/Roles"))
const Authentication = React.lazy(() =>
  import("../pages/administration/Authentication")
)
const EntitySharingPolicies = React.lazy(() =>
  import("../pages/administration/EntitySharing")
)

// encryption pages
const KMSPage = React.lazy(() => import("../pages/encryption/KMSPage"))
const CTEPage = React.lazy(() => import("../pages/encryption/CTEPage"))
const EncryptionOverviewPage = React.lazy(() =>
  import("../pages/encryption/EncryptionOverviewPage")
)

const Networks = React.lazy(() => import("../pages/administration/Networks"))
const Tags = React.lazy(() => import("../pages/administration/Tags"))
const Diagnostics = React.lazy(() =>
  import("../pages/administration/Diagnostics")
)
const Maintenance = React.lazy(() =>
  import("../pages/administration/Maintenance")
)
const Migration = React.lazy(() =>
  import("../pages/administration/MigrationDBRegistration")
)
const GettingStarted = React.lazy(() =>
  import("../pages/gettingStarted/GettingStarted")
)
const ApiExplorer = React.lazy(() => import("../pages/Swagger"))

function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/regCluster"
        element={
          <ProtectedRoute>
            <Suspense
              fallback={
                <Loader
                  tip={rb.LOADING}
                  overlay={true}
                  data-test-id="overlay"
                />
              }>
              <RegisterCluster />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/eraconfig"
        element={
          <ProtectedRoute>
            <Suspense
              fallback={
                <Loader
                  tip={rb.LOADING}
                  overlay={true}
                  data-test-id="overlay"
                />
              }>
              <EraConfig />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        }>
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route
          index
          path="dashboard"
          element={
            <Suspense
              fallback={
                <Loader
                  tip={rb.LOADING}
                  overlay={true}
                  data-test-id="overlay"
                />
              }>
              <Dashboard />
            </Suspense>
          }
        />
        <Route path="databases">
          <Route
            path=""
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <DatabasesContainer />
              </Suspense>
            }>
            <Route
              index
              path=""
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <Databases />
                </Suspense>
              }></Route>
            <Route
              index
              path="sourceDatabases"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <DatabaseList type="source" />
                </Suspense>
              }
            />
            <Route
              index
              path="cloneDatabases"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <CloneList type="clone" />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="clusterSourceDb"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <ClusterDbDetails type="source" />
              </Suspense>
            }
          />
          <Route
            path="clusterCloneDb"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <ClusterDbDetails type="clone" />
              </Suspense>
            }
          />
          <Route
            path="sourceDatabase"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <DatabaseDetails type="source" />
              </Suspense>
            }
          />
          <Route
            path="cloneDatabase"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <DatabaseDetails type="clone" />
              </Suspense>
            }
          />
        </Route>

        <Route path="dp">
          <Route path="timemachines">
            <Route
              path=""
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <TimeMachinesContainer />
                </Suspense>
              }>
              {configFlags.TM_RELIABILITY || configFlags.TM_STORAGE ? (
                <Route
                  index
                  path=""
                  element={
                    <Suspense
                      fallback={
                        <Loader
                          tip={rb.LOADING}
                          overlay={true}
                          data-test-id="overlay"
                        />
                      }>
                      <TimeMachinesOverview />
                    </Suspense>
                  }
                />
              ) : (
                false
              )}
              <Route
                path={
                  configFlags.TM_RELIABILITY || configFlags.TM_STORAGE
                    ? "list"
                    : ""
                }
                index={!(configFlags.TM_RELIABILITY || configFlags.TM_STORAGE)}
                element={
                  <Suspense
                    fallback={
                      <Loader
                        tip={rb.LOADING}
                        overlay={true}
                        data-test-id="overlay"
                      />
                    }>
                    <TimeMachineList />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="timemachine"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <TimeMachineContainer />
                </Suspense>
              }>
              <Route
                index
                element={
                  <Suspense
                    fallback={
                      <Loader
                        tip={rb.LOADING}
                        overlay={true}
                        data-test-id="overlay"
                      />
                    }>
                    <TimeMachineDetailNew />
                  </Suspense>
                }
              />
              <Route
                path="snapshotmanagement"
                element={
                  <Suspense
                    fallback={
                      <Loader
                        tip={rb.LOADING}
                        overlay={true}
                        data-test-id="overlay"
                      />
                    }>
                    <SnapshotManagement />
                  </Suspense>
                }
              />
              <Route
                path="dataAccessMgmt"
                element={
                  <Suspense
                    fallback={
                      <Loader
                        tip={rb.LOADING}
                        overlay={true}
                        data-test-id="overlay"
                      />
                    }>
                    <DataAccessManagement />
                  </Suspense>
                }
              />
              <Route
                path="alerts"
                element={
                  <Suspense
                    fallback={
                      <Loader
                        tip={rb.LOADING}
                        overlay={true}
                        data-test-id="overlay"
                      />
                    }>
                    <TimeMachineAlerts />
                  </Suspense>
                }
              />
              <Route
                path="properties"
                element={
                  <Suspense
                    fallback={
                      <Loader
                        tip={rb.LOADING}
                        overlay={true}
                        data-test-id="overlay"
                      />
                    }>
                    <TimeMachineProperties />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="timemachine-old"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <TimeMachineDetail />
                </Suspense>
              }
            />
          </Route>

          <Route path="disasterrecovery" element={<div></div>}>
            <Route index path="overview" element={<div></div>}></Route>
            <Route path="list" element={<div></div>}></Route>
          </Route>
        </Route>

        <Route path="profiles" element={<Outlet />}>
          <Route
            path="software"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <SoftwareProfiles />
              </Suspense>
            }></Route>
          <Route
            path="profileDetail"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <SoftwareProfile />
              </Suspense>
            }
          />
          <Route
            path="compute"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <ComputeProfiles />
              </Suspense>
            }
          />
          <Route
            path="network"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <NetworkProfiles />
              </Suspense>
            }
          />
          <Route
            path="dbParameters"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <DBParamsProfiles />
              </Suspense>
            }
          />
          <Route
            path="windowsdomain"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <WindowsDomainProfiles />
              </Suspense>
            }
          />
        </Route>

        <Route path="dbservervms">
          <Route
            path=""
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <DbServerVMsContainer />
              </Suspense>
            }>
            <Route
              index
              path=""
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <DbServerVMs />
                </Suspense>
              }></Route>
            <Route
              path="list"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <DbServerList />
                </Suspense>
              }></Route>
          </Route>
          <Route
            path="dbServer"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <DbServerDetail type="source" />
              </Suspense>
            }
          />
          <Route
            path="clusterDbServer"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <ClusterDbServerDetail />
              </Suspense>
            }
          />
        </Route>

        <Route path="policies" element={<Outlet />}>
          <Route
            index
            path="sla"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <SLA />
              </Suspense>
            }
          />
          <Route
            path="maintenanceWindows"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <MaintenanceWindows />
              </Suspense>
            }
          />
          <Route
            path="mwDetail"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <MaintenanceWindow />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="operations"
          element={
            <Suspense
              fallback={
                <Loader
                  tip={rb.LOADING}
                  overlay={true}
                  data-test-id="overlay"
                />
              }>
              <OperationsContainer />
            </Suspense>
          }>
          <Route
            index
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <CurrentOperations />
              </Suspense>
            }
          />
          <Route
            path="scheduled"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <ScheduledOperations />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="alerts"
          element={
            <Suspense
              fallback={
                <Loader
                  tip={rb.LOADING}
                  overlay={true}
                  data-test-id="overlay"
                />
              }>
              <AlertsContainer />
            </Suspense>
          }>
          <Route
            index
            path=""
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <AlertsList />
              </Suspense>
            }
          />
          <Route
            path="policies"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <AlertsPolicies />
              </Suspense>
            }
          />
        </Route>

        <Route path="settings" element={<Outlet />}>
          <Route
            path="ndbservices"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <NDBServices />
              </Suspense>
            }
          />
          <Route
            path="nutanixClusters"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <NutanixClusters />
              </Suspense>
            }
          />
          <Route
            path="prismcentral"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <PrismCentral />
              </Suspense>
            }
          />
          <Route
            path="objectstore"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <ObjectStore />
              </Suspense>
            }
          />

          <Route path="access" element={<Outlet />}>
            <Route
              index
              path="users"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <Users />
                </Suspense>
              }
            />
            <Route
              path="groups"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <Groups />
                </Suspense>
              }
            />
            <Route
              path="roles"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <Roles />
                </Suspense>
              }
            />
            <Route
              path="authentication"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <Authentication />
                </Suspense>
              }
            />
            <Route
              path="entitysharingpolicy"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <EntitySharingPolicies />
                </Suspense>
              }
            />
          </Route>
          <Route path="encryption" element={<Outlet />}>
            <Route
              index
              path="kms"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <KMSPage />
                </Suspense>
              }
            />
            <Route
              index
              path="cte"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <CTEPage />
                </Suspense>
              }
            />
            <Route
              index
              path="kms/kmsDetails"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <EncryptionOverviewPage />
                </Suspense>
              }
            />
            <Route
              index
              path="kms/kMSConfig"
              element={
                <Suspense
                  fallback={
                    <Loader
                      tip={rb.LOADING}
                      overlay={true}
                      data-test-id="overlay"
                    />
                  }>
                  <KMSConfigPage />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="networks"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <Networks />
              </Suspense>
            }
          />
          <Route
            path="tags"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <Tags />
              </Suspense>
            }
          />
          <Route
            path="diagnostic"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <Diagnostics />
              </Suspense>
            }
          />
          <Route
            path="maintenance"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <Maintenance />
              </Suspense>
            }
          />
          <Route
            path="migration"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <Migration />
              </Suspense>
            }
          />
          <Route
            path="startEra"
            element={
              <Suspense
                fallback={
                  <Loader
                    tip={rb.LOADING}
                    overlay={true}
                    data-test-id="overlay"
                  />
                }>
                <GettingStarted />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="apiexplorer"
          element={
            <Suspense
              fallback={
                <Loader
                  tip={rb.LOADING}
                  overlay={true}
                  data-test-id="overlay"
                />
              }>
              <ApiExplorer />
            </Suspense>
          }></Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="eula" element={<Eula />} />
    </Routes>
  )
}

export default AppRoutes
