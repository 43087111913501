import { getEraConfig } from "./AppUtil"

let ec = getEraConfig()
window.addEventListener("eraConfig", (event) => {
  ec = event?.detail
})
export const setupConfigValue = (key, value) => {
  ec[key] = value
  localStorage.setItem("EraConfig", JSON.stringify(ec))
  ec = getEraConfig() // update the local variable
}

export const configFlags = {
  get DISABLE_CUSTOM_OS_PATCHING() {
    return ec?.["disable_custom_os_patching"] && ec.disable_custom_os_patching
  },
  get TM_RELIABILITY() {
    return (
      ec?.["disable_tm_health_checks"] &&
      !(ec.disable_tm_health_checks === "true")
    )
  },
  get TM_STORAGE_ENABLED() {
    return (
      ec?.["disable_ndb_storage_reporting"] &&
      ec.disable_ndb_storage_reporting === "false"
    )
  },
  get OBJECT_STORES_ENABLED() {
    return (
      ec?.["disable_object_stores_ui"] &&
      ec.disable_object_stores_ui === "false"
    )
  },
  get PRISM_CENTRAL_ENABLED() {
    return (
      ec?.["disable_domain_onboarding"] &&
      ec.disable_domain_onboarding === "false"
    )
  },
  get DISASTER_RECOVERY_ENABLED() {
    return (
      ec?.["disable_disaster_recovery"] &&
      ec.disable_disaster_recovery === "false"
    )
  },
  get MONGO_SHARDED_CLUSTER_ENABLED() {
    return (
      ec?.["disable_mongodb_sharded_cluster_provisioning"] &&
      ec.disable_mongodb_sharded_cluster_provisioning === "false"
    )
  },
  get MONGODB_CUSTOM_USER_ENABLED() {
    return (
      ec?.["disable_mongodb_custom_user"] &&
      ec.disable_mongodb_custom_user === "false"
    )
  },
  get POSTGRES_CUSTOM_USER_ENABLED() {
    return (
      ec?.["disable_postgres_custom_user"] &&
      ec.disable_postgres_custom_user === "false"
    )
  },
  get PUBLIC_KEY() {
    return Boolean(
      ec?.["mandate_public_key_flag"] && ec.mandate_public_key_flag === "false"
    )
  },
  get CTE_ENCRYPTION() {
    return {
      postgres_database:
        ec?.["disable_cte_interoperability"] &&
        ec.disable_cte_interoperability === "false"
    }
  },
  get DISABLE_CTE_INTEROPERABILITY() {
    return (
      ec?.["disable_cte_interoperability"] &&
      ec.disable_cte_interoperability === "true"
    )
  },
  get DISABLE_TDE_WITH_KMIP() {
    return ec?.["disable_tde_with_kmip"] && ec.disable_tde_with_kmip === "true"
  }
}
