import {
  FlexLayout,
  StackingLayout,
  Button,
  PlusIcon,
  VerticalSeparator
} from "@nutanix-ui/prism-reactjs"
import { Table } from "antd"
import { getResourceBundle } from "../../tools/AppUtil"
import { useMemo, useState } from "react"
import { KMSCTEDropDownKeys } from "./kmscontants"
import KMSConfigForm from "../Modals/encryption/KMSConfigForm"
import PropTypes from "prop-types"
import { KMSCTEListDropDown, KMSCTEPagination } from "./EncryptionComponents"
import PermissionControl from "../PermissionControl/PermissionControl"
import privileges from "../../constants/privileges"

const rb = getResourceBundle()

const KMSConfigList = ({
  listData,
  totalCount,
  pageConfig,
  selectedRowKeys,
  setSelectedRowKeys,
  currentPage,
  pageSize,
  onPageChange,
  onRemoveClick,
  kmsId,
  handleConfigSuccess
}) => {
  const [isFormVisible, setFormVisibility] = useState(false)
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }
  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange
  }
  const onMenuClick = (menudata) => {
    if (menudata.key === KMSCTEDropDownKeys.remove) {
      onRemoveClick()
    }
  }
  const openForm = () => {
    setFormVisibility(true)
  }
  const closeForm = () => {
    setFormVisibility(false)
  }
  const handleSuccess = (name) => {
    setFormVisibility(false)
    handleConfigSuccess(name)
  }

  const selectedListData = useMemo(() => {
    return listData.find((data) => data.id === selectedRowKeys[0])
  }, [selectedRowKeys, listData])

  return (
    <FlexLayout
      className="full-width"
      flexDirection="column"
      padding="0px-20px">
      <FlexLayout flexDirection="column" alignItems="flex-start"></FlexLayout>
      <StackingLayout>
        <PermissionControl privilege={privileges.MANAGE_KMS_CONFIG}>
          <FlexLayout alignItems="center">
            <Button onClick={openForm}>
              <PlusIcon /> {pageConfig.addButtonText}
            </Button>
            <VerticalSeparator
              appearance={
                VerticalSeparator.VerticalSeparatorAppearance.SECONDARY
              }
            />
            <KMSCTEListDropDown
              onMenuClick={onMenuClick}
              disabled={selectedListData?.databaseCount === 0 ? false : true}
              hideUpdate={true}
            />
          </FlexLayout>
        </PermissionControl>
        <StackingLayout itemSpacing="10px">
          <KMSCTEPagination
            paginationText={rb.formatString(
              pageConfig.paginationText,
              totalCount
            )}
            currentPage={currentPage}
            pageSize={pageSize}
            total={totalCount}
            onPageChange={onPageChange}
          />
          <Table
            rowSelection={rowSelection}
            columns={pageConfig.tableColumns}
            dataSource={listData || []}
            className="complete-width era-table"
            rowKey={(record) => record.id}
            pagination={false}
          />
          <KMSCTEPagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={totalCount}
            onPageChange={onPageChange}
          />
        </StackingLayout>
        {isFormVisible && (
          <KMSConfigForm
            visible={isFormVisible}
            kmsId={kmsId}
            onSuccess={handleSuccess}
            onFormClose={closeForm}
          />
        )}
      </StackingLayout>
    </FlexLayout>
  )
}

KMSConfigList.propTypes = {
  listData: PropTypes.array.isRequired,
  totalCount: PropTypes.number,
  pageConfig: PropTypes.object,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  onRemoveClick: PropTypes.func,
  kmsId: PropTypes.string.isRequired,
  handleConfigSuccess: PropTypes.func
}

export default KMSConfigList
