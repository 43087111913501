const PathConstants = {
  HOME: "/",
  DASHBOARD: "dashboard",
  OVERVIEW: "overview",
  LIST: "list",
  DATABASES: "databases",
  DATA_PROTECTION: "dataprotection",
  TIME_MACHINES: "timemachines",
  DISASTER_RECOVERY: "disasterrecovery",
  CLUSTERS: "clusters",
  PROFILES: "profiles",
  SOFTWARES: "softwares",
  COMPUTES: "computes",
  DB_PARAMS: "databaseparameters",
  WINDOWS_DOMAINS: "windowsdomains",
  DB_SERVER_VMS: "dbservers",
  POLICIES: "policies",
  ALERTS: "alerts",
  OPERATIONS: "operations",
  CURRENT: "current",
  SCHEDULED: "scheduled",
  TAGS: "tags",
  NDB_SERVICES: "ndbservices",
  PRISM_CENTRALS: "prismcentrals",
  OBJECT_STORES: "objectstores",
  DIAGNOSTICS: "diagnostics",
  SETTINGS: "settings",
  USERS: "users",
  GROUPS: "groups",
  ROLES: "roles",
  ENTITY_SHARING_POLICIES: "entitysharingpolicies",
  SLAS: "slas",
  MAINTENANCE: "maintenance",
  NETWORKS: "networks",
  MIGRATION: "migration",
  GETTING_STARTED: "startEra",
  ENCRYPTIONKMS: "encryption/kms",
  ENCRYPTIONCTE: "encryption/cte"
}

export default PathConstants
