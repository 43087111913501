import {
  FlexItem,
  FlexLayout,
  StackingLayout,
  Title
} from "@nutanix-ui/prism-reactjs"
import PropTypes from "prop-types"
import "./layouts.less"

/**
 * StartScreenLayout is a flexible layout component that displays an image, a title, and content with an optional button.
 * It is typically used as a starting or empty state for a page.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.imageSrc - The source URL of the image to be displayed.
 * @param {string} props.titleText - The title text displayed at the top of the layout.
 * @param {React.ReactNode} props.labelContent - The content displayed below the title. This can be a text string or any React child element (e.g., JSX).
 * @param {React.ReactNode} props.buttonElement - The button element rendered below the label content, typically for triggering actions such as opening forms.
 *
 * @returns {JSX.Element} - A JSX element that renders the start screen layout with an image, title, content, and an optional action button.
 *
 * @example
 * const labelContent = (
 *   <TextLabel type="secondary" className="label-text">
 *     Welcome to the application! Please follow the instructions below to get started.
 *   </TextLabel>
 * );
 *
 * <StartScreenLayout
 *   imageSrc="https://example.com/welcome-image.png"
 *   titleText="Welcome"
 *   labelContent={labelContent}
 *   buttonElement={
 *     <Button onClick={handleClick}>
 *       <PlusIcon size="small" aria-hidden="true" />
 *       Get Started
 *     </Button>
 *   }
 * />
 */

const StartScreenLayout = ({
  imageSrc,
  titleText,
  labelContent,
  buttonElement
}) => {
  return (
    <FlexLayout
      data-item-spacing="20px"
      alignItems="center"
      itemFlexBasis="100pc"
      className="layout-screen align-center"
      justifyContent="center">
      <StackingLayout
        className="help-text-start-screen"
        data-item-spacing="20px">
        <FlexItem>
          <img alt="cte-start" src={imageSrc} className="era-icon" />
        </FlexItem>
        <FlexItem className="margin-t-20">
          <Title size="h1">{titleText}</Title>
        </FlexItem>
        <FlexItem className="margin-t-20">{labelContent}</FlexItem>
        <FlexItem className="margin-t-20">{buttonElement}</FlexItem>
      </StackingLayout>
    </FlexLayout>
  )
}

StartScreenLayout.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  labelContent: PropTypes.node.isRequired, // Accepts any valid React node (text or component)
  buttonElement: PropTypes.node // Optional button or element
}

export default StartScreenLayout
