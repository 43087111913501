import { useMemo, useState } from "react"
import {
  FormItemInput,
  FormItemProvider,
  Alert,
  HideIcon,
  ShowIcon
} from "@nutanix-ui/prism-reactjs"
import PropTypes from "prop-types"
import { getResourceBundle } from "../../tools/AppUtil"
import { CustomLabel } from "../../tools/ComponentUtil"
import TextFileInput from "./TextFileInput"
import "./FormFieldGenerator.less"

const rb = getResourceBundle()

const FormFileTextInput = FormItemProvider(TextFileInput)

/**
 * FormFieldGenerator
 *
 * This component dynamically generates form fields based on a provided field configuration object.
 * It supports various input types including text, number, password, and a custom fileText type.
 *
 * @component
 *
 * @typedef {Object} FieldConfig
 * @property {string} label - The label text for the form field.
 * @property {string} name - The name and id of the form field.
 * @property {"text" | "number" | "password" | "fileText" | "alert"} type - The type of input field.
 * @property {string} [placeholder] - The placeholder text for the input field.
 * @property {boolean} [optional=false] - Whether the field is optional.
 * @property {boolean} [customLabel=false] - Whether to use a custom label with a tooltip.
 * @property {string} [helperText] - The helper text to show in a tooltip for the label.
 * @property {boolean} [disabled=false] - Whether the field is disabled.
 * @property {boolean} [disableOnUpdate=false] - Whether the field is disabled when updating.
 * @property {boolean} [visible=true] - Whether the field is visible when updating.
 * @property {string} [subType] - Whether the field has nested type.
 * @param {Object} props - The props for the FormFieldGenerator component.
 * @param {FieldConfig} props.field - The configuration object for the form field.
 * @param {string} props.value - The current value of the form field.
 * @param {boolean} [props.hasError=false] - Whether the form field is in an error state.
 * @param {string} [props.errorMsg] - The error message to display when the field has an error.
 * @param {function} [props.onChange] - Callback triggered when the input value changes.
 * @param {boolean} [props.isUpdate=false] - Whether the form is in update mode.
 *
 * @example
 * <FormFieldGenerator
 *   field={{
 *     label: "Username",
 *     name: "username",
 *     type: "text",
 *     placeholder: "Enter your username",
 *     optional: false,
 *     customLabel: true,
 *     helperText: "Your unique username for login"
 *   }}
 *   value="JohnDoe"
 *   hasError={false}
 *   errorMsg=""
 *   onChange={(e) => handleInputChange(e.target.value)}
 * />
 *
 * @example
 * <FormFieldGenerator
 *   field={{
 *     label: "Certificate",
 *     name: "certificate",
 *     type: "fileText",
 *     optional: true,
 *     customLabel: true,
 *     helperText: "Upload a certificate file or enter certificate text"
 *   }}
 *   value=""
 *   hasError={false}
 *   errorMsg=""
 *   onChange={(e) => handleCertificateChange(e.target.value, e.target.fileName)}
 * />
 *
 * @returns {React.Element} A form field component based on the provided configuration.
 */
const FormFieldGenerator = ({
  field,
  value,
  hasError,
  errorMsg,
  disabled,
  optional,
  onChange = () => {}
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const createLabelText = useMemo(() => {
    if (!field) return ""

    const optionalText = `${field.label} (${rb.OPTIONAL})`
    return optional ? `${optionalText}` : field.label
  }, [field, optional])

  if (field.type === "alert") {
    return (
      <Alert
        type={field.subType}
        message={field.label}
        visible={field.visible}
        showCloseIcon={true}
      />
    )
  }

  if (field.type === "fileText") {
    return (
      <FormFileTextInput
        label={
          field?.customLabel ? (
            <CustomLabel text={createLabelText} helperText={field.helperText} />
          ) : (
            createLabelText
          )
        }
        error={hasError}
        helpText={errorMsg}
        key={field.name}
        required={!optional}
        onChange={onChange}
        value={value}
        id={field.name}
        disabled={disabled}
        data-test-id={field.name}
      />
    )
  }

  const getSuffixIcon = () => {
    if (field.type === "password") {
      if (showPassword) {
        return (
          <HideIcon
            data-test-id="hideIcon"
            onClick={() => setShowPassword(false)}
          />
        )
      } else {
        return (
          <ShowIcon
            data-test-id="showIcon"
            onClick={() => setShowPassword(true)}
          />
        )
      }
    }
    return null
  }

  return (
    <FormItemInput
      id={field.name}
      label={
        field?.customLabel ? (
          <CustomLabel text={createLabelText} helperText={field.helperText} />
        ) : (
          createLabelText
        )
      }
      type={field.type && showPassword ? "text" : field.type}
      placeholder={field.placeholder}
      required={!optional}
      value={value}
      disabled={disabled}
      key={field.name}
      onChange={onChange}
      error={hasError}
      helpText={errorMsg}
      data-test-id={field.name}
      className="form-generator-input"
      suffix={getSuffixIcon()}
    />
  )
}

FormFieldGenerator.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["text", "number", "password", "fileText", "alert"])
      .isRequired, // Restrict type
    placeholder: PropTypes.string,
    optional: PropTypes.bool,
    customLabel: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    subType: PropTypes.string
  }).isRequired,
  value: PropTypes.string,
  hasError: PropTypes.bool,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func
}
export default FormFieldGenerator
