import {
  FileInput,
  FlexLayout,
  FormItemTextArea,
  Input,
  Link,
  Radio,
  RadioGroup
} from "@nutanix-ui/prism-reactjs"
import { useRef, useState } from "react"
import { getResourceBundle } from "../../tools/AppUtil"
import "../Modals/encryption/encryptionmodal.less"

const rb = getResourceBundle()

const INPUT_TYPES_LABELS = {
  text: rb.TEXT,
  uploadCertificatie: rb.UPLOAD_CERTIFICATE
}

/**
 * TextFileInput Component
 *
 * This component provides a dual-mode input for text or file upload. It allows users to switch between
 * entering text directly or uploading a file. When a file is uploaded, its content is read and treated as text input.
 *
 * @component
 * @param {Object} props - The props for the TextFileInput component.
 * @param {Function} [props.onChange=() => {}] - Callback function triggered when the input value changes.
 *                                              It receives an object with 'value', 'id', and 'fileName' properties.
 * @param {string} [props.value=""] - The current value of the text input.
 * @param {string} props.id - The id to be used for the input element.
 *
 * @example
 * return (
 *   <TextFileInput
 *     onChange={(e) => console.log(e.target.value, e.target.fileName)}
 *     value=""
 *     id="certificate-input"
 *   />
 * )
 *
 * @returns {React.Element} A component with radio buttons to switch between text input and file upload,
 *                          and the corresponding input field based on the selection.
 */
const TextFileInput = ({ onChange = () => {}, value = "", id }) => {
  const [selectedInputType, setSelectedInputType] = useState(
    INPUT_TYPES_LABELS.text
  )
  const [fileName, setFileName] = useState("")

  const inputRef = useRef(null)

  const handleOnChange = (text, selectedFileName = "") => {
    onChange({ target: { value: text, id: id, fileName: selectedFileName } })
  }

  const handleOnFileChange = (files) => {
    const file = files?.[0]
    if (file) {
      setFileName(files?.[0]?.name)
      const reader = new FileReader()
      reader.onload = (e) => {
        handleOnChange(e.target.result, files?.[0]?.name)
      }
      reader.readAsText(file)
    }
  }

  const handleOpenSelectFile = () => {
    inputRef.current.click()
  }

  const handleRemoveSelectedFile = () => {
    setFileName("")
  }

  const handleInputTypeChange = (nextSelectedValue) => {
    setSelectedInputType(nextSelectedValue)
    setFileName("")
    handleOnChange("")
  }

  const handleTextChange = (e) => {
    handleOnChange(e.target.value)
  }

  return (
    <FlexLayout flexDirection="column">
      <FlexLayout>
        <RadioGroup
          name="my-radio-group-3"
          data-test-id="horizontal"
          layout={RadioGroup.RadioGroupLayout.HORIZONTAL}
          onChange={handleInputTypeChange}
          selectedValue={selectedInputType}>
          <Radio value={INPUT_TYPES_LABELS.text}>
            {INPUT_TYPES_LABELS.text}
          </Radio>
          <Radio
            wrapperProps={{ "data-test-id": "radio-2" }}
            value={INPUT_TYPES_LABELS.uploadCertificatie}>
            {INPUT_TYPES_LABELS.uploadCertificatie}
          </Radio>
        </RadioGroup>
      </FlexLayout>
      {selectedInputType === INPUT_TYPES_LABELS.uploadCertificatie && (
        <FlexLayout itemFlexBasis="100pc">
          {fileName ? (
            <Input
              readOnly
              label="File Name"
              value={fileName}
              suffix={
                <Link onClick={handleRemoveSelectedFile}>{rb.REMOVE}</Link>
              }
            />
          ) : (
            <FileInput
              enableDragDrop={false}
              onFileChange={handleOnFileChange}
              enableFileSelect={false}
              inputRef={inputRef}
              label={rb.UPLOAD_FILE}
              content={
                <Input
                  readOnly
                  defaultValue={fileName}
                  name="select-file-upload"
                  placeholder={rb.SELECT_FILE_TO_UPLOAD}
                  suffix={
                    <Link onClick={handleOpenSelectFile}>{rb.UPLOAD}</Link>
                  }
                />
              }
            />
          )}
        </FlexLayout>
      )}
      {selectedInputType === INPUT_TYPES_LABELS.text && (
        <FlexLayout itemFlexBasis="100pc" className="textArea-kms-config">
          <FormItemTextArea
            id="certificate-text"
            data-test-id="certificate-text"
            placeholder={rb.KMS_CFG_CERTIFICATE_PLACEHOLDER}
            disabled={selectedInputType !== INPUT_TYPES_LABELS.text}
            value={value}
            onChange={handleTextChange}
          />
        </FlexLayout>
      )}
    </FlexLayout>
  )
}

export default TextFileInput
