import {
  Link,
  ChevronLeftIcon,
  FlexLayout,
  Loader,
  Menu,
  MenuItem,
  MenuGroup,
  VerticalSeparator,
  Dropdown,
  Badge,
  Tooltip,
  TextLabel,
  BackIcon
} from "@nutanix-ui/prism-reactjs"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import "./TopBar.less"
import LongTableText from "../../common/LongTableText"

function TopBar(props) {
  const handleMenuClick = (item) => {
    if (
      typeof props.selectedMenu === "string" &&
      item.key === props.selectedMenu
    ) {
      return false
    } else if (
      Array.isArray(props.selectedMenu) &&
      props.selectedMenu.includes(item.key)
    ) {
      return false
    }
    if (props.onSelectMenu) {
      props.onSelectMenu(item)
    }
  }

  function separateDropdownComponents(items, index) {
    if (index < 0) {
      return [[], []]
    }
    if (index > items?.length) {
      return [items, []]
    }

    const menubarItem = items?.slice(0, index)
    const dropdownItem = items?.slice(index)

    return [menubarItem, dropdownItem || []]
  }

  const indexToSplit = 6
  const [menubarItem, dropdownItem] = separateDropdownComponents(
    props.menuList,
    indexToSplit
  )

  const dropDownMenu = (
    <Menu
      appearance={Menu.MenuAppearance.COMPACT}
      onClick={(menuItem) => {
        handleMenuClick(menuItem)
      }}>
      {dropdownItem.map((item, i) => (
        <MenuItem key={item}>{item}</MenuItem>
      ))}
    </Menu>
  )
  const navigate = useNavigate()
  const backButton = () => {
    navigate(props.backAction ? props.backAction : -1)
  }

  return (
    <FlexLayout
      className="top-menu-bar"
      alignItems="center"
      justifyContent="space-between">
      <FlexLayout alignItems="center">
        {props?.titleBadge ? (
          <>
            <BackIcon onClick={backButton} className="back-button" />
            <VerticalSeparator className="separator-topbar" />
          </>
        ) : (
          ""
        )}
        {props?.title &&
          (props?.titleBadge ? (
            <Badge className="badge-topmenu" color="gray" count={props.title} />
          ) : (
            <div className="mainTitleTopBar">{props.title}</div>
          ))}
        {props?.titleBadge ? (
          props?.titleProperties ? (
            <div className="titleProperties">
              {(props?.titleProperties).map((item, index) => {
                return (
                  <div key={`titles-${index}`}>
                    <FlexLayout alignItems="center">
                      <div className="itemProperties">
                        <LongTableText text={item} />
                        {index !== props.titleProperties.length - 1 && (
                          <VerticalSeparator className="separator-topbar" />
                        )}
                      </div>
                    </FlexLayout>
                  </div>
                )
              })}
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <VerticalSeparator className="separator-topbar" />
        {menubarItem?.length ? (
          <Menu
            className="menu"
            data-test-id="left-menubar"
            role={Menu.MenuRole.MENUBAR}
            activeKeyPath={
              typeof props.selectedMenu === "string"
                ? [props.selectedMenu]
                : props.selectedMenu
            }
            openKeyMap={[props.openKeys]}
            onClick={(menuItem) => {
              handleMenuClick(menuItem)
            }}>
            {menubarItem.map((item, i) => {
              if (typeof item === "object") {
                return (
                  <MenuGroup
                    key={Object.keys(item)[0]}
                    title={Object.keys(item)[0]}
                    type="collapsible">
                    {item[Object.keys(item)].map((subItem, i) => {
                      return (
                        <MenuItem type="collapsible" key={subItem}>
                          {subItem}
                        </MenuItem>
                      )
                    })}
                  </MenuGroup>
                )
              }

              return <MenuItem key={item}>{item}</MenuItem>
            })}
          </Menu>
        ) : (
          ""
        )}
        {dropdownItem.length ? (
          <>
            <VerticalSeparator className="separator-topbar" />
            <Dropdown
              popup={dropDownMenu}
              getPopupPlacement={() => document.querySelector(".right-panel")}>
              More Items
            </Dropdown>
          </>
        ) : (
          ""
        )}
      </FlexLayout>
      <Loader
        loading={props.loading}
        tip=""
        overlay={false}
        style={{ zIndex: "1" }}>
        {props.subTitle ? (
          <div className="mainSubTitle">{props.subTitle}</div>
        ) : (
          ""
        )}
        {props.description ? (
          <div className="entity-description">{props.description}</div>
        ) : (
          ""
        )}
      </Loader>
      {props.showTooltip ? (
        <Tooltip
          oldTooltip={false}
          appearance={Tooltip.APPEARANCE.SECONDARY}
          content={props?.showTooltip?.content}
          popupPlacement="left"
          contentProps={{
            "data-test-id": "secondary-tooltip"
          }}>
          <TextLabel
            type={TextLabel.TEXT_LABEL_TYPE.TRIGGER}
            data-test-id="open-secondary-tooltip">
            {props?.showTooltip?.label}
          </TextLabel>
        </Tooltip>
      ) : null}
    </FlexLayout>
  )
}

TopBar.defaultProps = {
  loading: false
}

TopBar.propTypes = {
  subTitle: PropTypes.string,
  backButtonText: PropTypes.string,
  backAction: PropTypes.string,
  titleHeader: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  properties: PropTypes.array,
  menuList: PropTypes.array,
  selectedMenu: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  openKeys: PropTypes.array,
  onSelectMenu: PropTypes.func,
  loading: PropTypes.bool,
  customSidebar: PropTypes.element,
  titleBadge: PropTypes.bool,
  titleProperties: PropTypes.array,
  showTooltip: {
    label: PropTypes.string,
    context: PropTypes.element
  }
}

export default TopBar
