import { useState, useEffect, useCallback } from "react"

/**
 * Custom hook to handle API calls with loading, error handling, and data fetching.
 *
 * @param {function} fetchFunction - The function responsible for making the API request.
 *   It should return a Promise that resolves to the API response.
 * @param {Array} [props=[]] - Optional parameters to be passed to the `fetchFunction`.
 *
 * @returns {Object} - The returned object contains:
 * @returns {any} data - The data fetched from the API. Initially null until the API call completes.
 * @returns {boolean} isLoading - Indicates whether the API call is in progress.
 * @returns {null | string} error - The error message if the API call fails, otherwise null.
 * @returns {function} refetch - A function to manually trigger the API call again (useful for refreshing data).
 *
 * @example
 * // Example usage of the `useApi` hook:
 *
 * // Define the API function (fetchFunction)
 * export const getAllKMS = (limit = 20, offset = 0) => {
 *   return fetch(
 *     `https://example.com/api/kms/?limit=${limit}&offset=${offset}`,
 *     { headers: FetchUtil.headers() }
 *   );
 * };
 *
 * // Use the hook inside a component
 * const MyComponent = () => {
 *   const { data: kmsList, isLoading, error, refetch } = useApi(getAllKMS, [20, 0]);
 *
 *   if (isLoading) {
 *     return <div>Loading...</div>;
 *   }
 *
 *   if (error) {
 *     return <div>Error: {error}</div>;
 *   }
 *
 *   return (
 *     <div>
 *       <ul>
 *         {kmsList?.entities.map((kms) => (
 *           <li key={kms.id}>{kms.name}</li>
 *         ))}
 *       </ul>
 *       <button onClick={refetch}>Refresh Data</button>
 *     </div>
 *   );
 * };
 */
const useApi = (fetchFunction, props = []) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchData = useCallback(async () => {
    if (!fetchFunction) {
      return
    }
    setIsLoading(true)
    setError(null)

    try {
      const response = await fetchFunction(...props)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const result = await response.json()
      setData(result)
    } catch (error) {
      setError(error.message || "An error occurred")
    } finally {
      setIsLoading(false)
    }
  }, [fetchFunction, ...props])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, isLoading, error, refetch: fetchData }
}

export default useApi
