import {
  Alert,
  Button,
  FlexItem,
  FlexLayout,
  FormItemInput,
  Loader,
  Modal,
  StackingLayout,
  TextLabel
} from "@nutanix-ui/prism-reactjs"
import "./encryptionmodal.less"
import { useState } from "react"
import { getResourceBundle } from "../../../tools/AppUtil"
import { getHeaderActions } from "../../../tools/ComponentUtil"
import ApiInput from "../common/ApiInput"
import PropTypes from "prop-types"

const rb = getResourceBundle()

const ComponentUtil = { getHeaderActions }

const RemoveKMSCTE = ({
  visible,
  onClose,
  onDelete,
  data,
  isLoading,
  errorText,
  alertMessage,
  apiEquivalentUrl,
  supportPortalUrl = ""
}) => {
  const [inputValue, setInputValue] = useState("")
  const [inputError, setInputError] = useState("")
  const [showApiInput, setShowApiInput] = useState(false)

  const onInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const onRemoveClick = () => {
    if (inputValue === data?.name) {
      onDelete()
    } else {
      setInputError(rb.ENCRYPTION_REMOVE_MODAL_ERROR)
    }
  }

  const onShowApiInput = () => {
    setShowApiInput(true)
  }

  const onCloseApiInput = () => {
    setShowApiInput(false)
  }

  const _renderFooter = () => {
    return (
      <FlexLayout
        className="full-width"
        justifyContent="space-between"
        data-test-id="remove-kms-config-modal">
        <FlexItem>
          <Button
            data-test-id="remove-modal-api-button"
            onClick={onShowApiInput}
            type={Button.ButtonTypes.SECONDARY}>
            {rb.API_EQUIVALENT}
          </Button>
        </FlexItem>
        <FlexLayout itemSpacing="20px">
          <Button
            disabled={isLoading}
            data-test-id="remove-modal-cancel-button"
            type={Button.ButtonTypes.SECONDARY}
            onClick={onClose}>
            {rb.CANCEL}
          </Button>
          <Button
            data-test-id="remove-modal-remove-button"
            disabled={isLoading}
            type={Button.ButtonTypes.DESTRUCTIVE}
            onClick={onRemoveClick}>
            {rb.REMOVE}
          </Button>
        </FlexLayout>
      </FlexLayout>
    )
  }
  return (
    <Modal
      visible={visible}
      width={500}
      title={`${rb.REMOVE} ${data?.name}`}
      className="remove-kms-modal"
      headerActions={ComponentUtil.getHeaderActions(supportPortalUrl, onClose)}
      footer={_renderFooter()}>
      <Loader tip={rb.SUBMITTING} loading={isLoading}>
        <Alert
          type={errorText ? Alert.AlertTypes.ERROR : Alert.AlertTypes.WARNING}
          aria-live="off"
          showCloseIcon={false}
          message={errorText ? errorText : alertMessage}
        />
        <StackingLayout padding="20px">
          <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
            {rb.ENCRYPTION_REMOVE_MODAL_CONFIRMATION_TEXT_1}
            <span className="remove-label-bold">{data?.name}</span>
            {rb.ENCRYPTION_REMOVE_MODAL_CONFIRMATION_TEXT_2}
          </TextLabel>
          <FormItemInput
            label={rb.ENCRYPTION_REMOVE_MODAL_INPUT_LABEL}
            placeholder={rb.ENCRYPTION_REMOVE_MODAL_INPUT_PLACEHOLDER}
            value={inputValue}
            onChange={onInputChange}
            error={Boolean(inputError)}
            helpText={inputError}
            id="entityName"
            data-test-id="remove-modal-input"
          />
        </StackingLayout>
        <ApiInput
          visible={showApiInput}
          close={onCloseApiInput}
          method={"DELETE"}
          url={apiEquivalentUrl}
          data={{}}
        />
      </Loader>
    </Modal>
  )
}

RemoveKMSCTE.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  errorText: PropTypes.string,
  apiEquivalentUrl: PropTypes.string.isRequired,
  supportPortalUrl: PropTypes.string
}

export default RemoveKMSCTE
