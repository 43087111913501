import {
  Alert,
  AlertLayout,
  Button,
  ChevronDownIcon,
  Dropdown,
  FlexLayout,
  FormItemSelect,
  Link,
  Menu,
  MenuItem,
  Pagination,
  ShareArrowIcon,
  StackingLayout,
  TextLabel,
  Tooltip
} from "@nutanix-ui/prism-reactjs"
import PropTypes from "prop-types"
import { getHelpUrl, getResourceBundle } from "../../tools/AppUtil"
import { KMSCTEDropDownKeys } from "./kmscontants"
import "./encryption-components.less"

const rb = getResourceBundle()

export const KMSCTEListDropDown = ({
  onMenuClick,
  disabled = true,
  hideUpdate = false
}) => {
  return (
    <Dropdown
      id="list-page-dropdown"
      data-test-id="list-page-dropdown"
      key="create-btn"
      disabled={disabled}
      popup={
        <Menu
          id="vlanMenu"
          className="ntnx-actions-menu era-dropdown-menu"
          onClick={onMenuClick}>
          {!hideUpdate && (
            <MenuItem
              data-test-id="dropdown-update-button"
              key={KMSCTEDropDownKeys.update}>
              {rb.UPDATE}
            </MenuItem>
          )}
          <MenuItem
            data-test-id="dropdown-remove-button"
            key={KMSCTEDropDownKeys.remove}>
            {rb.REMOVE}
          </MenuItem>
        </Menu>
      }
      popupPlacement="bottomLeft">
      <Button
        data-test-id="list-page-action-button"
        id="list-page-action-button"
        className="h3-light"
        type="secondary">
        {rb.ACTIONS}
        <ChevronDownIcon />
      </Button>
    </Dropdown>
  )
}

KMSCTEListDropDown.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideUpdate: PropTypes.bool
}

// Viewing {cteData?.entities?.length} CTEs
export const KMSCTEPagination = ({
  total,
  pageSize,
  paginationText,
  currentPage,
  onPageChange
}) => {
  return (
    <FlexLayout className="full-width" justifyContent="space-between">
      <TextLabel type={TextLabel.TEXT_LABEL_TYPE.PRIMARY}>
        {paginationText || ""}
      </TextLabel>
      {total > pageSize && (
        <Pagination
          pageSize={pageSize}
          total={total}
          currentPage={currentPage}
          onChange={onPageChange}
          data-test-id="kms-cte-pagination"
        />
      )}
    </FlexLayout>
  )
}

KMSCTEPagination.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  paginationText: PropTypes.string
}

export const TDEAdditionalInfo = ({
  kmsList = [],
  configList = [],
  selectedKMS,
  selectedConfig,
  onKMSSelect,
  onConfigSelect,
  isSelected,
  isKMSLoading,
  isConfigLoading,
  kmsCfgIDError,
  kmsIDError,
  tdeEnabledProfile
}) => {
  const isConfigEmpty =
    selectedKMS?.id && !isConfigLoading && !configList.length
  const isKMSListEmpty = !isKMSLoading && !kmsList.length
  return (
    <StackingLayout>
      <TextLabel
        display={TextLabel.TEXT_LABEL_DISPLAY.BLOCK}
        className="description-text"
        type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}>
        {rb.NATIVE_DB_ENCRYPTION_DESC}
      </TextLabel>
      {!tdeEnabledProfile && (
        <Alert
          type={Alert.AlertTypes.WARNING}
          data-test-id="tde-disabled-alert"
          message={rb.NDB_TDE_SUPPORT_TEXT}
          showCloseIcon={false}
        />
      )}
      {tdeEnabledProfile && isSelected && (
        <Alert
          type={Alert.AlertTypes.WARNING}
          data-test-id="tde-key-rotation-alert"
          message={rb.TDE_KEY_ROTATION_WARNING}
          showCloseIcon={false}
        />
      )}
      {isSelected && tdeEnabledProfile && (
        <>
          {isKMSListEmpty && (
            <Alert
              type={Alert.AlertTypes.WARNING}
              data-test-id="tde-alert"
              message={
                <AlertLayout>
                  <TextLabel>
                    {rb.TDE_NO_KMS_FOUND_ALERT_TEXT}{" "}
                    <Link href="#/settings/encryption/kms" target="_blank">
                      {rb.GO_TO_SETTINGS}
                    </Link>
                  </TextLabel>
                </AlertLayout>
              }
              showCloseIcon={false}
            />
          )}
          {!isKMSListEmpty && (
            <>
              <TextLabel
                display={TextLabel.TEXT_LABEL_DISPLAY.BLOCK}
                className="description-text"
                type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}>
                {rb.NATIVE_DB_ENCRYPTION_INFO}
                <br />
                {rb.NATIVE_DB_ENCRYPTION_INFO2}{" "}
                <Link href="#/settings/encryption/kms" target="_blank">
                  {rb.GO_TO_SETTINGS} <ShareArrowIcon />
                </Link>
              </TextLabel>
              <FormItemSelect
                dropdownProps={{ showCustomScrollbar: true }}
                id="kms-dropdown"
                label={rb.SELECT_KMS_SERVER}
                placeholder={rb.SELECT_KMS_SERVER_PLACEHOLDER}
                selectedRow={selectedKMS}
                rowsData={kmsList}
                rowRenderer={(row) => row.name}
                onSelectedChange={onKMSSelect}
                error={kmsIDError}
              />
              <FormItemSelect
                dropdownProps={{ showCustomScrollbar: true }}
                id="id2"
                label={rb.CONFIGURATION_LABEL}
                placeholder={
                  isConfigEmpty
                    ? rb.NO_CONFIGURATION_FOUND
                    : rb.CONFIGURATION_LABEL_PLACEHOLDER
                }
                helpText={
                  isConfigEmpty && (
                    <TextLabel>
                      {rb.NO_CONFIGURATION_FOUND_DESC}{" "}
                      <Link
                        href={`#/settings/encryption/kms/kmsDetails/?id=${selectedKMS?.id}&name=${selectedKMS?.name}`}
                        target="_blank">
                        {rb.CREATE_NEW} <ShareArrowIcon />
                      </Link>
                    </TextLabel>
                  )
                }
                rowRenderer={(row) => row.name}
                onSelectedChange={onConfigSelect}
                selectedRow={selectedConfig}
                rowsData={configList}
                disabled={!selectedKMS?.id}
                loading={isConfigLoading}
                error={kmsCfgIDError}
              />
            </>
          )}
        </>
      )}
    </StackingLayout>
  )
}

TDEAdditionalInfo.propTypes = {
  kmsList: PropTypes.array,
  configList: PropTypes.array,
  selectedKMS: PropTypes.object,
  selectedConfig: PropTypes.object,
  onKMSSelect: PropTypes.func,
  onConfigSelect: PropTypes.func,
  isSelected: PropTypes.bool,
  isKMSLoading: PropTypes.bool,
  isConfigLoading: PropTypes.bool,
  kmsCfgIDError: PropTypes.bool,
  kmsIDError: PropTypes.bool
}

export const CTEAdditionalInfo = ({ isSelected }) => {
  const LEARN_MORE_URL = getHelpUrl("encryption/cte/provision")
  return (
    <StackingLayout>
      <TextLabel
        display={TextLabel.TEXT_LABEL_DISPLAY.BLOCK}
        className="description-text"
        type={TextLabel.TEXT_LABEL_TYPE.SECONDARY}>
        {rb.CTE_CHECKBOX_DESC}
        <Tooltip
          content={rb.CTE_TOOLTIP_CHECKBOX_DESC}
          placement="bottom"
          appearance={Tooltip.APPEARANCE.SECONDARY}>
          {rb.INTEROPERABILITY}
        </Tooltip>
        {rb.CTE_CHECKBOX_DESC_2}
      </TextLabel>
      {isSelected && (
        <Alert
          type={Alert.AlertTypes.INFO}
          className="encryption_provision_alertInfo"
          message={
            <AlertLayout>
              <TextLabel>{rb.CTE_CHECKBOX_ALERT_TEXT}</TextLabel>
              <Link target="_blank" href={LEARN_MORE_URL}>
                {rb.LEARN_CTE_PROCEEDURE}
              </Link>
            </AlertLayout>
          }
        />
      )}
    </StackingLayout>
  )
}

CTEAdditionalInfo.propTypes = {
  isSelected: PropTypes.bool.isRequired
}
