import { useMemo, useReducer, useState } from "react"
import TopBar from "../../components/entity-sidebar-left/TopBar/Topbar"
import {
  Alert,
  Button,
  Link,
  Loader,
  PlusIcon,
  TextLabel
} from "@nutanix-ui/prism-reactjs"
import keyManagementServer from "../../assets/img/keyManagementServer.svg"
import "./encryption.less"
import KMSConfigList from "../../components/KMS/KMSConfigList"
import StartScreenLayout from "../../components/layouts/StartScreenLayout"
import { useLocation, useNavigate } from "react-router-dom"
import { deleteKMSConfig, getConfigforKMS } from "../../REST/encryption"
import useApi from "../../hooks/useApi"
import { getHelpUrl, getResourceBundle } from "../../tools/AppUtil"
import {
  encryptionActionTypes,
  encryptionInitialState,
  encryptionReducer
} from "../../reducers/encryptionstate"
import RemoveKMSCTE from "../../components/Modals/encryption/RemoveKMSCTE"
import KMSConfigForm from "../../components/Modals/encryption/KMSConfigForm"
import { KMSConfigListColumns } from "../../components/KMS/kmscontants"
import PermissionControl from "../../components/PermissionControl/PermissionControl"
import { KMS_ENCRYPTION_SPEC } from "../../data/DataURLConstants"
import privileges from "../../constants/privileges"

const rb = getResourceBundle()

const SUPPORT_PORTAL_URL = getHelpUrl("encryption/kms/config/add")
const REMOVE_SUPPORT_PORTAL_URL = getHelpUrl("encryption/kms/config/remove")
const PAGE_LIMIT = 20

const labelContent = (
  <TextLabel type="secondary" className="label-text">
    {rb.KMS_CREATE_CONFIG_LABEL_TEXT_2}
    <br />
    {rb.KMS_CREATE_CONFIG_LABEL_TEXT_3}
    {rb.KMS_CREATE_CONFIG_LABEL_TEXT_4}
    <Link target="_blank" href={SUPPORT_PORTAL_URL}>
      {rb.SUPPORT_PORTAL}
    </Link>
  </TextLabel>
)

const KMSConfigPage = () => {
  const [state, dispatch] = useReducer(
    encryptionReducer,
    encryptionInitialState
  )

  const pageConfig = {
    addButtonText: rb.KMS_CREATE_CONFIG,
    paginationText: rb.ENCRYPTION_LIST_TITLE_KMS_CONFIG,
    tableColumns: KMSConfigListColumns
  }

  const {
    selectedRowKeys,
    showRemoveModal,
    removeFormError,
    isRemoveModalLoading
  } = state

  const [kmsConfigPageCount, setKmsConfigPageCount] = useState(1)
  const location = useLocation()
  const id = new URLSearchParams(location.search).get("id")
  const kmsName = new URLSearchParams(location.search).get("name")
  const {
    data: kmsConfigData,
    isLoading,
    refetch
  } = useApi(getConfigforKMS, [
    id,
    "mongodb_database",
    PAGE_LIMIT,
    (kmsConfigPageCount - 1) * PAGE_LIMIT
  ])

  const setSelectedRowKeys = (rows = []) => {
    dispatch({
      type: encryptionActionTypes.SET_SELECTED_ROW_KEYS,
      payload: rows
    })
  }

  const extractSelectedKMSConfig = useMemo(() => {
    const currentSelectedKey = selectedRowKeys?.[0]

    if (currentSelectedKey) {
      return kmsConfigData?.entities?.find(
        (kmsConfig) => kmsConfig.id === currentSelectedKey
      )
    }
    return null
  }, [kmsConfigData, selectedRowKeys])

  const [selectedMenuItem, setSelectedMenuItem] = useState(rb.CONFIGURATION)
  const [isFormVisible, setFormVisibility] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const navigate = useNavigate()

  const selectMenu = (item) => {
    if (item.key === rb.OVERVIEW) {
      setSelectedMenuItem(rb.OVERVIEW)
      navigate(`/settings/encryption/kms/kmsDetails?id=${id}&name=${kmsName}`, {
        replace: true
      })
    }
  }
  const onRemoveModalClose = () =>
    dispatch({ type: encryptionActionTypes.CLOSE_REMOVE_MODAL })

  const onRemoveClick = () => {
    dispatch({ type: encryptionActionTypes.OPEN_REMOVE_MODAL })
  }
  const removeKMSConfig = async () => {
    dispatch({ type: encryptionActionTypes.SET_REMOVE_FORM_LOADING })

    try {
      const res = await deleteKMSConfig(
        extractSelectedKMSConfig.kmsId,
        extractSelectedKMSConfig.id
      )
      if (res.ok) {
        refetch()
        dispatch({ type: encryptionActionTypes.CLOSE_REMOVE_MODAL })
        setShowAlert(true)
        setAlertMessage(
          rb.formatString(rb.CTE_TDE_ALERT_DELETE_MESSAGE, "Configuration")
        )
      } else {
        const resJson = await res.json()
        dispatch({
          type: encryptionActionTypes.SET_REMOVE_FORM_ERROR,
          payload: `${resJson?.reason || ""} ${resJson?.remedy || ""}`
        })
      }
    } catch (err) {
      dispatch({
        type: encryptionActionTypes.SET_REMOVE_FORM_ERROR,
        payload: `${err?.reason || ""}. ${err?.remedy || ""}`
      })
    }
  }
  const openForm = () => {
    setFormVisibility(true)
  }
  const closeForm = () => {
    setFormVisibility(false)
  }
  const handleSuccess = () => {
    setFormVisibility(false)
    refetch()
    setShowAlert(true)
    setAlertMessage(
      rb.formatString(rb.CTE_TDE_ALERT_SUCCESS_MESSAGE, "Configuration")
    )
  }
  const onPageChange = (page) => {
    setKmsConfigPageCount(page)
  }
  return (
    <Loader loading={isLoading} tip={rb.LOADING} className="full-width">
      <div className="app-content app-content-right app-wrapper-encyption encryption-page">
        <TopBar
          className="encryption-topbar"
          selectedMenu={selectedMenuItem}
          title={rb.KMS}
          menuList={[rb.OVERVIEW, rb.CONFIGURATION]}
          titleBadge={true}
          onSelectMenu={selectMenu}
          titleProperties={[kmsName]}
        />
        {showAlert && (
          <Alert
            showCloseIcon={true}
            type={Alert.AlertTypes.SUCCESS}
            message={alertMessage}
          />
        )}
        {kmsConfigData?.entities?.length > 0 ? (
          <KMSConfigList
            listData={kmsConfigData?.entities || []}
            totalCount={kmsConfigData?.count || 0}
            pageConfig={pageConfig}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            onRemoveClick={onRemoveClick}
            kmsId={id}
            handleConfigSuccess={handleSuccess}
            currentPage={kmsConfigPageCount}
            pageSize={PAGE_LIMIT}
            onPageChange={onPageChange}
          />
        ) : (
          <StartScreenLayout
            imageSrc={keyManagementServer}
            titleText={rb.KMS_CREATE_CONFIG_LABEL_TEXT_1}
            labelContent={labelContent}
            buttonElement={
              <PermissionControl privilege={privileges.MANAGE_KMS_CONFIG}>
                <Button onClick={openForm} data-test-id="create-config-btn">
                  <PlusIcon size="small" aria-hidden="true" />
                  {rb.KMS_CREATE_CONFIG}
                </Button>
              </PermissionControl>
            }
          />
        )}
        {showRemoveModal && (
          <RemoveKMSCTE
            visible={showRemoveModal}
            data={extractSelectedKMSConfig}
            onClose={onRemoveModalClose}
            onDelete={removeKMSConfig}
            errorText={removeFormError}
            isLoading={isRemoveModalLoading}
            data-test-id="remove-kms-config-modal"
            apiEquivalentUrl={
              KMS_ENCRYPTION_SPEC.url +
              "/" +
              id +
              "/cfg/" +
              extractSelectedKMSConfig.id
            }
            supportPortalUrl={REMOVE_SUPPORT_PORTAL_URL}
            alertMessage={rb.formatString(
              rb.ENCRYPTION_REMOVE_MODAL_ALERT_KMS_CONFIG,
              extractSelectedKMSConfig?.name
            )}
          />
        )}

        {isFormVisible && (
          <KMSConfigForm
            visible={isFormVisible}
            kmsId={id}
            onSuccess={handleSuccess}
            onFormClose={closeForm}
          />
        )}
      </div>
    </Loader>
  )
}

export default KMSConfigPage
