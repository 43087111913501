import { SETTINGS_SPEC } from "../data/DataURLConstants"
import FetchUtil from "../tools/FetchUtil"

export const updateTelemetrySetting = (body, user, pass) => {
  return FetchUtil.PUT(`${SETTINGS_SPEC.url}/era_telemetry`, body, user, pass)
}

export const getTelemetrySetting = () => {
  return fetch(`${SETTINGS_SPEC.url}/era_telemetry`, {
    headers: FetchUtil.headers()
  })
}
