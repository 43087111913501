import PropTypes from "prop-types"
import { hasPrivilege } from "../../tools/AuthUtil"

const AuthUtil = { hasPrivilege }

/**
 * PermissionControl Component
 *
 * A simple wrapper component that conditionally renders content based on user permissions
 * using the existing AuthUtil service.
 *
 * @example
 * // Basic usage
 * <PermissionControl privilege="MANAGE_CTE">
 *   <Button>Add CTE</Button>
 * </PermissionControl>
 *
 * @example
 * // With multiple privileges
 * <PermissionControl privileges={['EDIT_CTE', 'ADMIN']} operator="OR">
 *   <Button>Edit</Button>
 * </PermissionControl>
 */
const PermissionControl = ({
  // Single privilege or array of privileges required
  privilege,
  // Operator for multiple privileges ('AND' requires all, 'OR' requires any)
  operator = "OR",
  // Content to render if user has permission
  children,
  // Optional content to render if permission is denied
  fallback = null
}) => {
  /**
   * Checks if user has required privileges using AuthUtil
   * @param {string|string[]} requiredPrivileges - Privilege(s) to check
   * @returns {boolean} - Whether user has required privileges
   */
  const hasRequiredPrivileges = (requiredPrivileges) => {
    const privileges = Array.isArray(requiredPrivileges)
      ? requiredPrivileges
      : [requiredPrivileges]

    return operator === "AND"
      ? privileges.every(AuthUtil.hasPrivilege)
      : privileges.some(AuthUtil.hasPrivilege)
  }

  return hasRequiredPrivileges(privilege) ? children : fallback
}

// PropTypes for development-time type checking
PermissionControl.propTypes = {
  privilege: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  operator: PropTypes.oneOf(["AND", "OR"]),
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node
}

export default PermissionControl
