import {
  Alert,
  Button,
  Divider,
  FlexItem,
  FlexLayout,
  Form,
  FormItemSelect,
  FormLayout,
  FormSection,
  FullPageModal,
  Loader,
  TextLabel
} from "@nutanix-ui/prism-reactjs"
import { useState } from "react"
import PropTypes from "prop-types"
import { getHelpUrl, getResourceBundle } from "../../../tools/AppUtil"
import {
  CFG_DATABASE_OPTIONS,
  CFG_FIELD_NAMES,
  CFGInitialFormData,
  CFGIntitalFileNames,
  MongoCFGConfig
} from "./CFGFormConfig"
import FormFieldGenerator from "../../FormFieldGenerator/FormFieldGenerator"
import { getHeaderActions } from "../../../tools/ComponentUtil"
import "./encryptionmodal.less"
import { postKMSConfig } from "../../../REST/encryption"
import ApiInput from "../common/ApiInput"
import { KMS_ENCRYPTION_SPEC } from "../../../data/DataURLConstants"

const ComponentUtil = { getHeaderActions }
const rb = getResourceBundle()

const SUPPORT_PORTAL_URL = getHelpUrl("encryption/kms/config/add")

/**
 * KMSConfigForm Component
 *
 * This component renders a form for creating KMS (Key Management Service) configurations.
 * It allows users to input various details such as name, description, certificates, and master key ID.
 * The form includes validation, error handling, and submission functionality.
 *
 * @component
 * @param {Object} props - The props for the KMSConfigForm component.
 * @param {boolean} [props.visible=false] - Determines if the modal containing the form is visible.
 * @param {string} [props.kmsId=""] - The ID of the KMS to which the configuration will be added.
 * @param {Function} [props.onSuccess=() => {}] - Callback function to be called when the form is successfully submitted.
 * @param {Function} [props.onFormClose=() => {}] - Callback function to be called when the form/modal is closed.
 *
 * @example
 * return (
 *   <KMSConfigForm
 *     visible={true}
 *     kmsId="kms-123"
 *     onSuccess={() => console.log('Form submitted successfully')}
 *     onFormClose={() => console.log('Form closed')}
 *   />
 * )
 *
 * @returns {React.Element} A modal containing the KMS configuration form.
 */

const KMSConfigForm = ({
  visible = false,
  kmsId = "",
  onSuccess = () => {},
  onFormClose = () => {}
}) => {
  const [formData, setFormData] = useState(CFGInitialFormData)
  const [fileNames, setFileNames] = useState(CFGIntitalFileNames)
  const [formErrors, setFormErrors] = useState(CFGInitialFormData)
  const [isLoading, setIsLoading] = useState(false)
  const [serverError, setServerError] = useState("")
  const [showApiInput, setApiEquivalent] = useState(false)

  const onInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
    if (e.target.fileName) {
      setFileNames({
        ...fileNames,
        [e.target.id]: e.target.fileName
      })
    }
  }

  const validateFormFields = () => {
    const errors = {}
    MongoCFGConfig.forEach((field) => {
      const value = formData[field.name]
      if (!field?.optional && !value) {
        errors[field.name] = "Required"
      } else if (field.validation && value) {
        const { regex, errorMessage } = field.validation
        if (!regex.test(value)) {
          errors[field.name] = errorMessage
        }
      }
    })
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }

  const transformFormData = (formData) => {
    const newFormData = {
      databaseType: CFG_DATABASE_OPTIONS.key,
      [CFG_FIELD_NAMES.name]: formData.name,
      [CFG_FIELD_NAMES.description]: formData.description,
      details: {
        certificates: {
          [CFG_FIELD_NAMES.clientCert]: {
            data: formData.clientCert,
            fileName: fileNames.clientCert,
            password: formData.password
          },
          [CFG_FIELD_NAMES.caCert]: {
            data: formData.caCert,
            fileName: fileNames.caCert
          }
        },
        keys: {
          [CFG_FIELD_NAMES.masterKeyId]: formData.masterKeyId
        }
      }
    }
    return newFormData
  }

  const handleServerError = (error) => {
    setServerError(error)
    setIsLoading(false)
  }

  const handleSuccess = () => {
    setFormData(CFGInitialFormData)
    setFileNames(CFGIntitalFileNames)
    setFormErrors(CFGInitialFormData)
    setServerError("")
    setIsLoading(false)
    onSuccess(formData?.name)
  }

  const onCreateClick = async () => {
    const isvalid = validateFormFields()
    if (isvalid) {
      const transformedData = transformFormData(formData)
      try {
        setIsLoading(true)
        const res = await postKMSConfig(kmsId, transformedData)
        const resJson = await res.json()
        if (res.ok && resJson) {
          handleSuccess()
        } else
          handleServerError(
            `${resJson?.reason || ""}. ${resJson?.remedy || ""}`
          )
      } catch (error) {
        handleServerError(`${error?.reason || ""}. ${error?.remedy || ""}`)
      }
    }
  }

  const toggleApiInput = () => {
    setApiEquivalent(!showApiInput)
  }

  const _renderFooter = () => {
    return (
      <>
        <Divider />
        <FlexLayout
          className="footer-container"
          itemSpacing="10px"
          justifyContent="space-between">
          <FlexItem>
            <Button type="secondary" onClick={toggleApiInput}>
              {rb.API_EQUIVALENT}
            </Button>
          </FlexItem>
          <FlexLayout>
            <Button type={Button.ButtonTypes.SECONDARY} onClick={onFormClose}>
              {rb.CANCEL}
            </Button>
            <Button onClick={onCreateClick}>{rb.CREATE}</Button>
          </FlexLayout>
        </FlexLayout>
      </>
    )
  }

  return (
    <FullPageModal
      className="encryption-modal"
      onClose={onFormClose}
      visible={visible}
      title={rb.KMS_CONFIG_MODAL_TITLE}
      headerActions={ComponentUtil.getHeaderActions(
        SUPPORT_PORTAL_URL,
        onFormClose
      )}
      data-test-id="kms-config-form">
      <Loader
        tip={rb.SUBMITTING}
        loading={isLoading}
        className="full-width encryption-modal-loader">
        <Form>
          <Alert
            type={Alert.AlertTypes.ERROR}
            data-test-id="kms-cfg-form-error"
            message={serverError}
            visible={!!serverError}
            showCloseIcon={false}
          />
          <FormLayout contentWidth="500px" className="encryption-modal-body">
            <FlexLayout flexDirection="column" itemSpacing="20px">
              <TextLabel className="kms-config-label-form">
                {rb.KMS_CONFIG_MODAL_DESCRIPTION}
              </TextLabel>
            </FlexLayout>
            <FormItemSelect
              dropdownProps={{ showCustomScrollbar: true }}
              id="select_database"
              label={rb.DATABASE_ENGINE}
              selectedRow={CFG_DATABASE_OPTIONS}
              disabled
            />
            <FormSection>
              {MongoCFGConfig.map((field) => (
                <FormFieldGenerator
                  key={field.name}
                  field={field}
                  onChange={onInputChange}
                  hasError={Boolean(formErrors[field.name])}
                  errorMsg={formErrors[field.name]}
                  value={formData[field.name]}
                />
              ))}
            </FormSection>
          </FormLayout>
          <FormSection className="encryption-modal-footer">
            {_renderFooter()}
          </FormSection>
        </Form>
        {showApiInput && (
          <ApiInput
            visible={showApiInput}
            close={toggleApiInput}
            method="POST"
            url={KMS_ENCRYPTION_SPEC.url + "/" + kmsId + "/cfg/"}
            data={transformFormData(formData)}
          />
        )}
      </Loader>
    </FullPageModal>
  )
}

KMSConfigForm.propTypes = {
  visible: PropTypes.bool,
  kmsId: PropTypes.string,
  onSuccess: PropTypes.func,
  onFormClose: PropTypes.func
}

export default KMSConfigForm
