//  https://www.cluemediator.com/how-to-access-url-parameters-in-the-class-component-using-react-router-v6
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "./useAuth"

const withRouter = (WrappedComponent) => (props) => {
  const location = useLocation()
  const navigate = useNavigate()

  return <WrappedComponent {...props} location={location} navigate={navigate} />
}

export default withRouter
